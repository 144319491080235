import { Button, darken, styled } from '@mui/material';

export const ErrorButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.error.main,
	color: 'white',
	'&:hover': {
		backgroundColor: theme.palette.error.dark,
	},
}));

export const SuccessButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.success.main,
	color: 'white',
	'&:hover': {
		backgroundColor: theme.palette.success.dark,
	},
}));

export const MainQueueActionButton = styled(Button)(({ theme }) => ({
	margin: '0 0.8rem',
	padding: '0.8rem 1rem',
	borderRadius: '8px',
	fontSize: '1rem',
	boxShadow: theme.shadows[7],
}));

export const ReserveButton = styled(Button)(({ theme }) => ({
	margin: '1.25rem',
	padding: '0.8rem 1rem',
	borderRadius: '8px',
	fontSize: '1rem',
	boxShadow: theme.shadows[7],
}));

export const ErrorMainQueueActionButton = styled(MainQueueActionButton)(
	({ theme }) => ({
		background: theme.palette.error.main,
		color: 'white',
		'&:hover': {
			background: darken(theme.palette.error.dark, 0.1),
		},
	})
);
