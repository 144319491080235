import React, { useState } from 'react';
import {
	ErrorButton,
	ErrorMainQueueActionButton,
} from '../../../general-components/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import PrintIsFinishedDialog from './PrintIsFinished.Dialog';
import { UserObj } from '../../../models/Queue.models';
import { StringUtils } from '../../../utils/string.utils';
import { useTranslation } from 'react-i18next';

export interface PrintIsFinishedProps {
	user: UserObj;
	mainQueueAction?: boolean;
}

const PrintIsFinished: React.FC<PrintIsFinishedProps> = ({
	user,
	mainQueueAction,
}) => {
	const [showDialog, setShowDialog] = useState<boolean>(false);

	const { t } = useTranslation();

	const handleCloseDialog = () => {
		setShowDialog(false);
	};

	const handleOpenDialog = () => {
		setShowDialog(true);
	};
	return (
		<>
			{mainQueueAction ? (
				<ErrorMainQueueActionButton
					variant="contained"
					onClick={handleOpenDialog}
					endIcon={<CancelIcon />}
				>
					{t('Avslutt')}{' '}
					{user.firstName
						? StringUtils.add_s_ToName(user.firstName)
						: t('gjeldende')}{' '}
					{t('print')}
				</ErrorMainQueueActionButton>
			) : (
				<ErrorButton
					variant="contained"
					onClick={handleOpenDialog}
					endIcon={<CancelIcon />}
				>
					{t('Avslutt print')}
				</ErrorButton>
			)}
			<PrintIsFinishedDialog
				open={showDialog}
				onClose={handleCloseDialog}
				user={user}
			/>
		</>
	);
};

export default PrintIsFinished;
