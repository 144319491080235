import { css, keyframes } from 'styled-components';

type TimingFunction =
	| 'linear'
	| 'ease'
	| 'ease-in'
	| 'ease-out'
	| 'ease-in-out';
type IterationCount = number | 'infinite' | 'initial' | 'inherit';

interface BasicAnimationParams {
	animationLengthMS: number;
	timingFunction: TimingFunction;
	iterationCount: IterationCount;
}

// ============ Pulse animation ============
interface PulseAnimationProps extends BasicAnimationParams {
	scaleReduce: number;
}
export const pulseAnimation = (props: PulseAnimationProps) => css`
	${pulseKeyframes(props.scaleReduce)} ${pulseParams(props)}
`;

// Changed
const pulseKeyframes = (
	scaleReduce: number
): ReturnType<typeof keyframes> => keyframes`
    0% { transform: scale(1)}
    20% { transform: scale(${scaleReduce})}
    40% { transform: scale(1)}
    100% { transform: scale(1)}
`;

const pulseParams = (props: BasicAnimationParams) =>
	`${props.animationLengthMS}ms ${props.iterationCount} ${props.timingFunction}`;

// ============= Background gradient animation ===========
export const gradientBackgroundAnimation = (props: BasicAnimationParams) => css`
	${gradientBackgroundKeyframes} ${gradientBackgroundParams(props)}
`;

const gradientBackgroundKeyframes: ReturnType<typeof keyframes> = keyframes`
     0% { background-position: 0% 50% }
	50% { background-position: 100% 50%	}
	100% { background-position: 0% 50% } 
`;

const gradientBackgroundParams = (props: BasicAnimationParams) =>
	`${props.animationLengthMS}ms ${props.iterationCount} ${props.timingFunction}`;
