import { ApiStatus } from './../models';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Location, LocationBasic } from './locationSlice.model';
import { LocationApi } from '../../api/location.api';
import { compareNames } from '../User/userSlice';

const alphabeticallySort = (a: Location, b: Location) => {
	const nameCompare = compareNames(a.name, b.name);
	if (nameCompare !== 0) return nameCompare;
	return compareNames(a.name, b.name);
};

export interface ILocationState {
	locations: Location[];
	getLocationsStatus: ApiStatus;
	updateIpAddressStatus: ApiStatus;
	onTheLab: boolean;
}

const initialState: ILocationState = {
	locations: [],
	getLocationsStatus: 'idle',
	updateIpAddressStatus: 'idle',
	onTheLab: false,
};

export const getLocations = createAsyncThunk(
	'location/getLocations',
	async (): Promise<Location[]> => {
		const data: Location[] = await LocationApi.getLocations();
		return data;
	}
);

export const createLocation = createAsyncThunk(
	'location/createLocation',
	async ({ name, ipAddress }: LocationBasic): Promise<Location> => {
		const data: Location = await LocationApi.createLocation(name, ipAddress);
		return data;
	}
);

export const deleteLocation = createAsyncThunk(
	'location/deleteLocation',
	async ({ id }: { id: number }): Promise<number> => {
		await LocationApi.deleteLocation(id);
		return id;
	}
);

export const updateIpAddress = createAsyncThunk(
	'location/updateIpAddress',
	async (params: {
		locationId: number;
		ipAddress: string;
	}): Promise<Location> => {
		const location = await LocationApi.updateLocation(params.locationId, {
			ipAddress: params.ipAddress,
		});
		return location;
	}
);

export const locationSlice = createSlice({
	name: 'location',
	initialState,
	reducers: {
		setOnTheLab: (state, action: PayloadAction<boolean>) => {
			state.onTheLab = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getLocations.pending, (state) => {
				state.getLocationsStatus = 'loading';
			})
			.addCase(getLocations.fulfilled, (state, action) => {
				state.getLocationsStatus = 'idle';
				state.locations = [...action.payload].sort(alphabeticallySort);
			})
			.addCase(getLocations.rejected, (state, action) => {
				state.getLocationsStatus = 'failed';
			})
			.addCase(createLocation.fulfilled, (state, action) => {
				state.locations.push(action.payload);
			})
			.addCase(deleteLocation.fulfilled, (state, action) => {
				state.locations = state.locations.filter(
					(loc) => loc.id !== action.payload
				);
			})
			.addCase(updateIpAddress.pending, (state, action) => {
				state.updateIpAddressStatus = 'loading';
			})
			.addCase(updateIpAddress.fulfilled, (state, action) => {
				state.updateIpAddressStatus = 'idle';
				if (state.locations) {
					state.locations = [
						...state.locations?.filter((loc) => loc.id !== action.payload.id),
						action.payload,
					].sort(alphabeticallySort);
				} else {
					state.locations = [action.payload];
				}
			})
			.addCase(updateIpAddress.rejected, (state, action) => {
				state.updateIpAddressStatus = 'failed';
			});
	},
});

export const { setOnTheLab } = locationSlice.actions;

export const locationsSel = (state: RootState) => state.location.locations;
export const getLocationsStatusSel = (state: RootState) =>
	state.location.getLocationsStatus;
export const onTheLabSel = (state: RootState) => state.location.onTheLab;

export default locationSlice.reducer;
