import { Button, CircularProgress, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormErrorMessage } from '../../../general-components/Form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	resetPassword,
	resetPasswordStatusSel,
} from '../../../redux/User/userSlice';
import {
	FormWrapper,
	StyledForm,
	StyledTextField,
} from '../../../styledcomponents/SimpleForms/SimpleForm';
import { StringUtils } from '../../../utils/string.utils';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';

export interface PasswordResetProps {}

type FormInputs = {
	phoneNumber: string;
	pinCode: string;
};

const PasswordResetForm: React.FC<PasswordResetProps> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const passwordResetStatus = useAppSelector(resetPasswordStatusSel);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<FormInputs>();

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		const phoneNumber = StringUtils.addPhoneCode(
			getValues('phoneNumber').trim(),
			'no'
		);
		await dispatch(resetPassword(phoneNumber));
		enqueueSnackbar(t('Ny pinkode er sendt'), {
			variant: 'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		});
		navigate('/login');
	};

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<StyledForm noValidate autoComplete="off">
				<StyledTextField
					id="phoneNumber"
					{...register('phoneNumber', {
						required: t('Telefonnummer er påkrevd. Skriv inn Telefonnummer.'),
						maxLength: 8,
						minLength: 8,
					})}
					color="secondary"
					label={t("Telefonnummer")}
					placeholder="987 65 432"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">+47</InputAdornment>
						),
					}}
				/>
				{errors.phoneNumber && <FormErrorMessage error={errors.phoneNumber} />}
				<Button
					endIcon={
						passwordResetStatus === 'loading' ? (
							<CircularProgress size={20} />
						) : (
							<LaunchIcon />
						)
					}
					type="submit"
					variant="contained"
					color="secondary"
					id="submit"
					disabled={passwordResetStatus === 'loading'}
					sx={{ mt: 2 }}
				>
					{t('Få ny pinkode på mobilen')}
				</Button>
			</StyledForm>
		</FormWrapper>
	);
};

export default PasswordResetForm;
