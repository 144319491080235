import {
	faCheckCircle,
	faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';

type FeedbackType = 'error' | 'success';

interface FeedbackProps {
	type: FeedbackType;
	message: string | JSX.Element;
}

const Feedback = ({ type, message }: FeedbackProps) => {
	return (
		<>
			{type === 'error' ? (
				<FeedbackWrapper type={type}>
					<StyledFontAwesomeIcon icon={faExclamationCircle} />
					<Typography variant="subtitle1">{message}</Typography>
				</FeedbackWrapper>
			) : (
				<FeedbackWrapper type={type}>
					<StyledFontAwesomeIcon icon={faCheckCircle} />
					<Typography variant="subtitle1">{message}</Typography>
				</FeedbackWrapper>
			)}
		</>
	);
};

interface FeedbackWrapperProps {
	type: FeedbackType;
}

const FeedbackWrapper = withTheme(styled.div<FeedbackWrapperProps>`
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	align-items: center;
	color: white;

	background: ${(props) =>
		props.type === 'error'
			? props.theme.palette.error.main
			: props.theme.palette.success.main};
`);

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
	margin-right: 1rem;
`;

export default Feedback;
