import { Box, CircularProgress, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
interface StatBoxProps {
	text: string;
	stat: number | string | undefined;
	max?: number | string;
	statStyle?: CSSProperties;
	maxStyle?: CSSProperties;
	loading?: boolean;
}
const StatBox: React.FC<StatBoxProps> = ({
	text,
	stat,
	max,
	statStyle,
	maxStyle,
	loading,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				mt: 2,
				mb: 2,
				flexDirection: 'column',
				flex: 1,
			}}
		>
			{loading && (
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress sx={{ padding: 1 }} size="2.125rem" />
				</Box>
			)}
			{!loading && (
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Typography
						variant="h4"
						sx={statStyle}
						textAlign="center"
						display="inline"
					>
						{stat}
					</Typography>
					<Typography variant="h4" whiteSpace={'pre-wrap'}>
						{max !== undefined && ' / '}
					</Typography>
					{max !== undefined && (
						<Typography variant="h4" sx={maxStyle}>
							{max}
						</Typography>
					)}
				</Box>
			)}
			<Typography textAlign="center">{text}</Typography>
		</Box>
	);
};
export default StatBox;
