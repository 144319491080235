import React from 'react';
import styled from 'styled-components';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import {
	QueueState,
	EnqueuedUser,
	CompletedEnqueuedUser,
} from '../../../models/Queue.models';
import { B } from '../../../styledcomponents/Typography/Typography';
import QueueActions from './QueueActions';
import PrintStatusTag from './PrintStatusTag';
import { TimeUtils } from '../../../utils/time.utils';
import { useAppSelector } from '../../../redux/hooks';
import { remainingTimeInStateSecSel, } from '../../../redux/Queue/queueSlice';
import { Alignment } from '../../../styledcomponents/General/alignment';
import PrintSuccessfulTag from '../../../general-components/PrintSuccessfulTag';
import { useTranslation } from 'react-i18next';

export interface QueueItemDetailsAccordionProps {
	enqueuedUser: EnqueuedUser | CompletedEnqueuedUser;
	onTheLab: boolean;
	

}

const QueueItemDetailsAccordion: React.FC<QueueItemDetailsAccordionProps> = ({
	enqueuedUser,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const remainingTimeInStateSec = useAppSelector(remainingTimeInStateSecSel);
	const { queueState } = enqueuedUser;
	const { t } = useTranslation();


	return (
		<DetailWrapper>
			<InfoWrapper>
				{queueState !== QueueState.Completed && (
					<Typography variant="subtitle1">
						{t('Posisjon i kø')}
						{': '}
						<B>{enqueuedUser.position}</B>
					</Typography>
				)}
				<Typography variant="subtitle1">
					{t('Telefonnummer')}
					{': '}
					<B>{enqueuedUser.phoneNumber}</B>
				</Typography>

				{enqueuedUser.customer && enqueuedUser.project !== null && (
					<>
						<Typography variant="subtitle1">
							{t('Kunde')}
							{': '}
							<B>{enqueuedUser.customer}</B>
						</Typography>
						<Typography variant="subtitle1">
							{t('Prosjekt')}
							{': '}
							<B>{enqueuedUser.project}</B>
						</Typography>
					</>
				)}

				{queueState === QueueState.ReadyToPrint && (
					<Typography variant="subtitle1">
						{t('Frist for å starte print')}
						{': '}
						<B>
							{TimeUtils.toHHMMSS(remainingTimeInStateSec).HtMmDisplayString}
						</B>
					</Typography>
				)}

				{queueState === QueueState.Printing &&
					enqueuedUser.startTime &&
					enqueuedUser.endTime && (
						<>
							<Typography variant="subtitle1">
								{t('Print startet:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										enqueuedUser.startTime
									)}
								</B>
							</Typography>

							<Typography variant="subtitle1">
								{t('Print ferdig:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										enqueuedUser.endTime
									)}
								</B>
							</Typography>

							<Typography variant="subtitle1">
								{t('Tid igjen:')}{' '}
								<B>
									{
										TimeUtils.toHHMMSS(remainingTimeInStateSec)
											.HtMmDisplayString
									}
								</B>
							</Typography>
						</>
					)}

				{queueState === QueueState.Finished &&
					enqueuedUser.startTime &&
					enqueuedUser.endTime && (
						<>
							<Typography variant="subtitle1">
								{t('Print startet:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										enqueuedUser.startTime
									)}
								</B>
							</Typography>

							<Typography variant="subtitle1">
								{t('Print ferdig:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										enqueuedUser.endTime
									)}
								</B>
							</Typography>
						</>
					)}

				{queueState === QueueState.Completed && (
					<>
						{enqueuedUser.printDurationSec && (
							<Typography variant="subtitle1">
								{t('Varighet:')}{' '}
								<B>
									{
										TimeUtils.toHHMMSS(enqueuedUser.printDurationSec)
											.HtMmDisplayString
									}
								</B>
							</Typography>
						)}
						{enqueuedUser.startTime && (
							<Typography variant="subtitle1">
								{t('Print startet:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										enqueuedUser.startTime
									)}
								</B>
							</Typography>
						)}
						{enqueuedUser.endTime && (
							<Typography variant="subtitle1">
								{t('Print ferdig:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										enqueuedUser.endTime
									)}
								</B>
							</Typography>
						)}
						{(enqueuedUser as CompletedEnqueuedUser).originalEndTime && (
							<Typography variant="subtitle1">
								{t('Print opprinnelig ferdig:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										(enqueuedUser as CompletedEnqueuedUser).originalEndTime
									)}
								</B>
							</Typography>
						)}
						{(enqueuedUser as CompletedEnqueuedUser).finishedTime && (
							<Typography variant="subtitle1">
								{t('Tidspunkt fjernet fra kø:')}{' '}
								<B>
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										(enqueuedUser as CompletedEnqueuedUser).finishedTime
									)}
								</B>
							</Typography>
						)}
						{(enqueuedUser as CompletedEnqueuedUser).queueCountWhenFinished >=
							0 && (
								<Typography variant="subtitle1">
									{t('Brukere i kø når ferdig:')}{' '}
									<B>
										{
											(enqueuedUser as CompletedEnqueuedUser)
												.queueCountWhenFinished
										}
									</B>
								</Typography>
							)}
						<Typography variant="subtitle1">
							{t('Antall utsettelser:')}{' '}
							<B>
								{(enqueuedUser as CompletedEnqueuedUser).extendPrintTimeCount}
							</B>
						</Typography>

						<Typography variant="subtitle1">
							{t('Resultat:')}{' '}
							<B>
								{(enqueuedUser as CompletedEnqueuedUser).printSuccessful
									? t('vellykket')
									: t('mislykket')}
							</B>
						</Typography>
					</>
				)}
			</InfoWrapper>

			{queueState === QueueState.Completed ? (
				<Alignment.CenterAlignHorVerWrapper style={{ fontSize: '10rem' }}>
					<PrintSuccessfulTag
						printSuccessful={
							(enqueuedUser as CompletedEnqueuedUser).printSuccessful
						}
						typographyVariant="h4"
					/>
				</Alignment.CenterAlignHorVerWrapper>
			) : (
				<QueueActions queueState={queueState} userQueueItem={enqueuedUser} enqueuedUser={enqueuedUser} />
			)}

			{isMobile && <PrintStatusTag queueState={queueState} />}
		</DetailWrapper>
	);
};

const DetailWrapper = withTheme(styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    ${(props) => props.theme.breakpoints.down('sm')} {
        grid-template-columns: auto;
    }
`);

const InfoWrapper = withTheme(styled.div`
    ${(props) => props.theme.breakpoints.down('sm')} {
        margin-bottom: 1rem;
    }
`);


export default QueueItemDetailsAccordion;
