import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Button } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
	firstNameSel,
	lastNameSel,
	logoutUserChain,
} from '../redux/User/userSlice';
import { Typography } from '@mui/material';
import { getRandomElementFromArray } from '../utils/array.utils';
import { Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { onTheLabSel } from '../redux/Location/locationSlice';
import { useTranslation } from 'react-i18next';

export interface ProfileMenuProps {}

const ProfileMenu: React.FC<ProfileMenuProps> = () => {
	const dispatch = useAppDispatch();
	const firstName = useAppSelector(firstNameSel);
	const lastName = useAppSelector(lastNameSel);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const onTheLab = useAppSelector(onTheLabSel);
	const { t } = useTranslation();

	const emojies = ['🎉', '😀', '👋', '🤗'];
	const [currentEmoji] = useState(getRandomElementFromArray(emojies));

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleProfileClick = () => {
		handleClose();
		navigate('/profile');
	};

	const handleDashboardClick = () => {
		handleClose();
		navigate('/dashboard');
	};

	const handleLogout = () => {
		dispatch(logoutUserChain());
		handleClose();
		navigate(`/${onTheLab ? 'lab' : ''}`);
		enqueueSnackbar(t('Du ble logget ut'), {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		});
	};

	return (
		<>
			<Button
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleMenu}
				color="inherit"
				style={{ textTransform: 'none' }}
				startIcon={<AccountCircle />}
			>
				{firstName} {lastName}
			</Button>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: PaperPropsStyle,
				}}
			>
				<Typography variant="h5" style={{ padding: ' 0.5rem 1rem' }}>
					{t('Hei')} {firstName} {currentEmoji}
				</Typography>
				<Divider />
				<MenuItem onClick={handleDashboardClick}>{t('Dashboard')}</MenuItem>
				<MenuItem onClick={handleProfileClick}>{t('Profil')}</MenuItem>
				<MenuItem onClick={handleLogout}>{t('Logg ut')}</MenuItem>
			</Menu>
		</>
	);
};

const PaperPropsStyle = {
	maxWidth: '40rem',
	'& .MuiMenuItem-root:hover': {
		backgroundColor: '#11133C',
		color: 'white',
	},
};

export default ProfileMenu;
