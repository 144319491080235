import React, { useEffect } from 'react';
import {
	getPrintersStatusSel,
	printersSel,
} from '../../../redux/Printer/printerSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Printer } from '../../../redux/Printer/printeSlice.model';
import PrinterButton from './PrinterButton';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { FeedbackOuterWrapper } from './QueueList';
import Feedback from '../../../general-components/Feedback';
import { refreshPrinterAndQueue } from '../../../redux/Queue/queueSlice';
import { useTranslation } from 'react-i18next';

export interface PrinterButtonListProps {}

const PrinterButtonList: React.FC<PrinterButtonListProps> = () => {
	const dispatch = useAppDispatch();
	const printers = useAppSelector(printersSel);
	const getPrintersStatus = useAppSelector(getPrintersStatusSel);
	const { t } = useTranslation();

	const pollIntervalMS = 55000;

	const activePrinters = printers.filter(
		(printer) => printer.printerState !== 'Disabled'
	);
	const activePrintersCount = activePrinters.length;

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(refreshPrinterAndQueue());
		}, pollIntervalMS);
		return () => {
			clearInterval(interval);
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(refreshPrinterAndQueue());
	}, [dispatch]);

	if (getPrintersStatus === 'failed') {
		return (
			<FeedbackOuterWrapper style={{ marginBottom: '0.5rem' }}>
				<Feedback
					type="error"
					message={t('Kunne ikke laste inn printere. Prøv på nytt')}
				/>
			</FeedbackOuterWrapper>
		);
	}

	return (
		<MainWrapper>
			<Typography variant="h5">{t('Velg en printer')}</Typography>
			{printers.length === 0 && (
				<Typography variant="subtitle1">
					{t(`Det er ingen printere registrert på dette kontoret. Kontakt
					administrator for å legge til flere printere.`)}
				</Typography>
			)}
			<ListWrapper $printersCount={activePrintersCount}>
				{activePrinters.map((printer: Printer) => (
					<PrinterButton key={printer.id} printer={printer} />
				))}
			</ListWrapper>
		</MainWrapper>
	);
};

const MainWrapper = withTheme(styled.div`
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`);

interface ListWrapperProps {
	$printersCount: number;
}

const ListWrapper = withTheme(styled.div<ListWrapperProps>`
	padding: 1rem;
	display: grid;
	justify-content: center;
	grid-gap: 0.4rem;
	grid-auto-flow: row dense;
	grid-template-columns: ${(props) =>
		`repeat(${Math.min(props.$printersCount, 4)}, 1fr)`};
	${(props) => props.theme.breakpoints.down('lg')} {
		grid-template-columns: ${(props) =>
			`repeat(${Math.min(props.$printersCount, 3)}, 1fr)`};
	}
	${(props) => props.theme.breakpoints.down('md')} {
		grid-template-columns: ${(props) =>
			`repeat(${Math.min(props.$printersCount, 2)}, 1fr)`};
	}
	${(props) => props.theme.breakpoints.down('sm')} {
		grid-template-columns: auto;
	}
`);

export default PrinterButtonList;
