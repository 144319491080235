import {
	TableRow,
	TableCell,
	IconButton,
	Select,
	MenuItem,
	CircularProgress,
	darken,
	lighten,
} from '@mui/material';
import { Location } from '../../../../redux/Location/locationSlice.model';
import { UserResponse } from '../../../../redux/User/userSlice.models';
import { Role } from '../../../../redux/User/userSlice.models';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
	updateLocation,
	updateLocationStatusSel,
	updateRole,
	updateRoleStatusSel,
} from '../../../../redux/User/userSlice';
import { useSnackbar } from 'notistack';
import { successSnack } from '../LocationsTable';
import { useTranslation } from 'react-i18next';

interface UserRowProps {
	user: UserResponse;
	deleteUser: (id: number) => void;
	deleteColor: string;
	role: Role | undefined;
	myUserId: number | undefined;
	locations: Location[];
}

const GetOptions = (role: Role) => {
	const { t } = useTranslation();
	
	const items = [
		<MenuItem key="User" value={'User'}>
			{t('Bruker')}
		</MenuItem>,
		<MenuItem key="Admin" value={'Admin'}>
			Administrator
		</MenuItem>,
	];
	items.push(
		<MenuItem key="SuperAdmin" value={'SuperAdmin'} disabled={role === 'Admin'}>
			Global Administrator
		</MenuItem>
	);
	return items;
};

const getRoleSelector = (
	myRole: Role | undefined,
	userRole: Role,
	handleUpdateRole: (newRole: Role) => void,
	disabled: boolean,
	loading: boolean
) => {
	switch (myRole) {
		case 'Admin':
			return (
				<Select
					disabled={userRole === 'SuperAdmin' || disabled || loading}
					value={userRole}
					onChange={(event) => handleUpdateRole(event.target.value as Role)}
				>
					{GetOptions(myRole)}
				</Select>
			);
		case 'SuperAdmin':
			return (
				<Select
					disabled={disabled || loading}
					value={userRole}
					onChange={(event) => handleUpdateRole(event.target.value as Role)}
				>
					{GetOptions(myRole)}
				</Select>
			);
		default:
			return <></>;
	}
};

const UserRow = ({
	user,
	deleteUser,
	deleteColor,
	role,
	myUserId,
	locations,
}: UserRowProps) => {
	const dispatch = useAppDispatch();
	const updateLocationStatus = useAppSelector(updateLocationStatusSel);
	const updateRoleStatus = useAppSelector(updateRoleStatusSel);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleUpdateRole = async (role: Role) => {
		try {
			await dispatch(updateRole({ userId: user.id, role })).unwrap();
			enqueueSnackbar(t('Bruker oppdatert'), successSnack);
		} catch (error) {
			enqueueSnackbar(t(`Noe gikk galt. Kunne ikke oppdatere.`), {
				...successSnack,
				variant: 'error',
			});
		}
	};

	const handleUpdateLocation = async (locationId: number) => {
		try {
			const updatedUser = {
				userId: user.id,
				locationId,
			};
			await dispatch(updateLocation(updatedUser));
			enqueueSnackbar(t('Bruker oppdatert'), successSnack);
		} catch (error) {
			enqueueSnackbar(t(`Noe gikk galt. Kunne ikke oppdatere.`), {
				...successSnack,
				variant: 'error',
			});
		}
	};

	const loading =
		updateLocationStatus === 'loading' || updateRoleStatus === 'loading';

	return (
		<TableRow
			key={user.id}
			style={{
				background: loading ? darken('#fff', 0.1) : '#fff',
				fontWeight: user.id === myUserId ? 'bold' : 'normal',
			}}
		>
			<TableCell component="th" scope="row" style={{ fontWeight: 'inherit' }}>
				{`${user.firstName} ${user.lastName}`}
			</TableCell>
			<TableCell align="right" style={{ fontWeight: 'inherit' }}>
				{user.phoneNumber}
			</TableCell>
			{role === 'SuperAdmin' && (
				<TableCell align="right" style={{ fontWeight: 'inherit' }}>
					<Select
						value={user.locationId}
						onChange={(event) =>
							handleUpdateLocation(event.target.value as number)
						}
					>
						{locations.map((loc) => (
							<MenuItem key={loc.id} value={loc.id}>
								{loc.name}
							</MenuItem>
						))}
					</Select>
				</TableCell>
			)}
			<TableCell align="right">
				{loading ? (
					<CircularProgress size={27} />
				) : (
					getRoleSelector(
						role,
						user.role,
						handleUpdateRole,
						myUserId === user.id,
						loading
					)
				)}
			</TableCell>
			<TableCell align="right">{user.gramFilamentUsedCurrentMonth}</TableCell>
			<TableCell align="right">
				<IconButton
					size="small"
					disabled={user.id === myUserId}
					onClick={() => deleteUser(user.id)}
				>
					<FontAwesomeIcon
						icon={faTrashAlt}
						color={
							user.id === myUserId ? lighten(deleteColor, 0.6) : deleteColor
						}
					/>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default UserRow;
