import styled from 'styled-components';
import { ButtonBase, Typography, Badge } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { Printer } from '../../../redux/Printer/printeSlice.model';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	activePrinterIdSel,
	setActivePrinterId,
} from '../../../redux/Printer/printerSlice';
import { lighten } from '@mui/material';
import {
	currentUserInPrinterIdSel,
	refreshPrinterAndQueue,
} from '../../../redux/Queue/queueSlice';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';

export interface PrinterButtonProps {
	printer: Printer;
}
const PrinterButton: React.FC<PrinterButtonProps> = ({ printer }) => {
	const dispatch = useAppDispatch();
	const activePrinterId = useAppSelector(activePrinterIdSel);
	const currentUserInPrinterId = useAppSelector(currentUserInPrinterIdSel);
	const { t } = useTranslation();

	const isPrinterDisabled = printer.printerState === 'Disabled';

	if (isPrinterDisabled) {
		return null;
	}

	const handlePrinterChange = () => {
		if (activePrinterId !== printer.id) {
			dispatch(setActivePrinterId(printer.id));
			dispatch(refreshPrinterAndQueue());
		}
	};

	return (
		<ButtonWrapper
			isactive={(activePrinterId === printer.id).toString()}
			onClick={handlePrinterChange}
			disabled={isPrinterDisabled} // Add a disabled prop to your styled component
		>
			<PrinterName variant="h6" style={{ fontWeight: 'bold' }}>
				{printer.modelName}
			</PrinterName>

			<PrinterInfo>{printer.printerSpesifics}</PrinterInfo>

			<PrinterLogo>
				{!isPrinterDisabled && (
					<Badge
						overlap="circular"
						badgeContent={<StyledAccountCircle color="primary" />}
						invisible={currentUserInPrinterId !== printer.id}
					/>
				)}
				<UsersInQueueCountWrapper $usersCount={printer.numUsersInQueue}>
					{isPrinterDisabled ? (
						<Typography component={'span'} variant="h6" fontSize={16}>
							{t('Deaktivert')}
						</Typography>
					) : printer.numUsersInQueue === 0 ? (
						<Typography component={'span'} variant="h6">
							{t('Ledig')}
						</Typography>
					) : (
						<>
							<Typography component={'span'} variant="h5">
								{printer.numUsersInQueue}
							</Typography>
							<Typography component={'span'}>{t('i kø')}</Typography>
						</>
					)}
				</UsersInQueueCountWrapper>
			</PrinterLogo>
		</ButtonWrapper>
	);
};

interface ButtonWrapperProps {
	isactive: string;
}

const ButtonWrapper = withTheme(styled(ButtonBase)<ButtonWrapperProps>`
	min-height: 8rem;
	max-height: 9rem;
	padding: 1rem;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1.2fr 1fr;

	box-shadow: ${(props) =>
		props.isactive === 'true'
			? props.theme.shadows[15]
			: props.theme.shadows[3]};
	transform: ${(props) =>
		props.isactive === 'true' ? 'scale(1.03)' : 'scale(0.92)'};
	background: ${(props) =>
		props.isactive === 'true'
			? props.theme.palette.primary.main
			: lighten(props.theme.palette.primary.light, 0.75)};

	color: ${(props) =>
		props.isactive === 'true'
			? props.theme.palette.grey[100]
			: props.theme.palette.grey[900]};
	border-radius: 10px;
	transition: all 0.2s;
	&:hover {
		background: ${(props) =>
			props.isactive !== 'true' &&
			lighten(props.theme.palette.primary.light, 0.85)};
		cursor: pointer;
		box-shadow: ${(props) =>
			props.isactive !== 'true' && props.theme.shadows[6]};
	}
	pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`);

const PrinterName = withTheme(styled(Typography)`
	grid-area: 1 / 1 / span 1 / span 1;
`);

const PrinterInfo = withTheme(styled(Typography)`
	grid-area: 2 / 1 / span 1 / span 1;
`);

const PrinterLogo = withTheme(styled(Typography)`
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: 1 / 2 / span 2 / span 1;
`);

const StyledAccountCircle = withTheme(styled(AccountCircle)`
	background: ${(props) => lighten(props.theme.palette.secondary.light, 0.75)};
	border-radius: 50%;
	font-size: 2rem;
`);

interface UsersInQueueCountWrapperProps {
	$usersCount: number;
}

const UsersInQueueCountWrapper = withTheme(
	styled.span<UsersInQueueCountWrapperProps>`
		color: ${(props) => props.theme.palette.background.default};
		width: 5.2rem;
		height: 5.2rem;
		border-radius: 50%;
		background: ${(props) =>
			props.$usersCount === 0
				? props.theme.palette.tertiary.main
				: props.theme.palette.secondary.main};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	`
);
export default PrinterButton;
