import {
	DialogTitle,
	DialogContent,
	Typography,
	Button,
	DialogActions,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { add } from 'date-fns/add';
import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getUserData, userIdSel } from '../../../redux/User/userSlice';
import {
	firstUserInQueueSel,
	getEnqueuedUsers,
	startOrProlongPrintStatusSel,
	startOrProlongPrint,
} from '../../../redux/Queue/queueSlice';
import { useSnackbar } from 'notistack';
import { StartOrProlongPrintInput } from '../../../redux/Queue/queueSlice.models';
import { useCallback } from 'react';
import { DateUtils } from '../../../utils/date.utils';
import { TimeUtils } from '../../../utils/time.utils';
import { StyledDialog } from '../../../styledcomponents/Dialog/Dialog';
import { useTranslation } from 'react-i18next';

export type EstimateType = 'estimateTime' | 'addTime';

export interface EstimatePrintTimeDialogProps {
	open: boolean;
	onClose: () => void;
	estimateType: EstimateType;
}

const EstimatePrintTimeDialog: React.FC<EstimatePrintTimeDialogProps> = ({
	open,
	onClose,
	estimateType,
}) => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const userId = useAppSelector(userIdSel);
	const firstUserInQueue = useAppSelector(firstUserInQueueSel);
	const startOrProlongPrintStatus = useAppSelector(
		startOrProlongPrintStatusSel
	);
	const [estimatedGrams, setEstimatedGrams] = useState<number>(0);
	const [remainingHours, setRemainingHours] = useState<number>(0);
	const [remainingMinutes, setRemainingMinutes] = useState<number>(0);
	const [endTimeEstimated, setEndTimeEstimated] = useState<Date | null>(() => {
		if (!firstUserInQueue.endTime) return null;
		return DateUtils.getLastDateFromList([
			firstUserInQueue.endTime,
			new Date(),
		]);
	});

	const [showDate, setShowDate] = useState<boolean>(false);

	const remainingTimeOptions = {
		hours: [5, 1],
		minutes: {
			regular: [10, 5, 1],
			irregular: [10, 1, 5],
		},
	};

	const estimatedGramsOptions = [100, 10, 1];

	const handleClose = () => {
		setEstimatedGrams(0);
		setRemainingHours(0);
		setRemainingMinutes(0);
		onClose();
	};

	const findEndTimeEstimated = useCallback(() => {
		if (
			remainingMinutes === 0 &&
			remainingHours === 0 &&
			!firstUserInQueue.endTime
		) {
			setEndTimeEstimated(null);
		} else {
			const now = new Date();
			const originalEndTime: Date = firstUserInQueue.endTime ?? now;
			const latestEndTime = DateUtils.getLastDateFromList([
				originalEndTime,
				now,
			]);
			const endTimeAdded: Date = add(latestEndTime, {
				hours: remainingHours,
				minutes: remainingMinutes,
			});
			setEndTimeEstimated(endTimeAdded);

			const printStartTime: Date =
				estimateType === 'estimateTime'
					? (firstUserInQueue.startTime ?? originalEndTime)
					: now;

			setShowDate(differenceInCalendarDays(endTimeAdded, printStartTime) > 0);
		}
	}, [
		remainingMinutes,
		remainingHours,
		firstUserInQueue.endTime,
		firstUserInQueue.startTime,
		estimateType,
	]);

	type ChangeTimeProps = {
		timeAmount: 'min' | 'hours';
		operation: 'add' | 'subtract';
		value: number;
	};

	const changeRemainingTime = ({
		timeAmount,
		operation,
		value,
	}: ChangeTimeProps) => {
		const newValue: number = operation === 'add' ? value : -value;
		if (timeAmount === 'hours') {
			const nextHours = remainingHours + newValue;
			setRemainingHours(nextHours >= 0 ? nextHours : 0);
		} else {
			const nextMinutes = remainingMinutes + newValue;
			const additionalHours = Math.floor(nextMinutes / 60);

			if (additionalHours > 0) {
				setRemainingHours(remainingHours + additionalHours);
				setRemainingMinutes(nextMinutes % 60 >= 0 ? nextMinutes % 60 : 0);
			} else {
				setRemainingMinutes(nextMinutes >= 0 ? nextMinutes : 0);
			}
		}
	};

	const getPrintDurationMS = (): number => {
		if (!endTimeEstimated) return 0;

		const now = new Date();
		if (estimateType === 'estimateTime') {
			return endTimeEstimated?.getTime() - now.getTime();
		} else {
			const originalEndTime: Date = firstUserInQueue.endTime ?? now;
			const latestEndTime = DateUtils.getLastDateFromList([
				originalEndTime,
				now,
			]);
			return endTimeEstimated?.getTime() - latestEndTime.getTime();
		}
	};

	const handleStartPrint = async () => {
		const printDurationSec: number = Math.floor(getPrintDurationMS() / 1000);
		if (!userId) {
			enqueueSnackbar(t('Du er ikke innlogget.'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
			return;
		}

		try {
			if (!userId || !printDurationSec) {
				throw new Error();
			}
			const printInput: StartOrProlongPrintInput = {
				printDurationSec,
				userId,
				gramFilament: estimatedGrams,
			};
			await dispatch(startOrProlongPrint(printInput)).unwrap();
			dispatch(getEnqueuedUsers());
			dispatch(getUserData());

			enqueueSnackbar(
				t('Printen din er estimert ferdig ') +
					`${
						endTimeEstimated &&
						TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(endTimeEstimated)
					}`,
				{
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
			handleClose();
		} catch (e) {
			enqueueSnackbar(t('Noe gikk galt! Prøv på nytt.'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	useEffect(() => {
		findEndTimeEstimated();
	}, [remainingHours, remainingMinutes, findEndTimeEstimated]);

	return (
		<StyledDialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
			maxWidth="lg"
		>
			<DialogTitle id="estimatePrintTime">
				{estimateType === 'estimateTime'
					? t('Informasjon om print')
					: t('Legg til tid')}{' '}
			</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1">
					{estimateType === 'estimateTime'
						? t('Hvor lenge varer printen?')
						: t('Hvor mye tid vil du legge til?')}{' '}
				</Typography>

				<RemainingTimeWrapper>
					<GridItem justifyContent="flex-end">
						{remainingTimeOptions.hours.map((value: number) => (
							<StyledButton
								key={value}
								variant="contained"
								color="primary"
								onClick={() =>
									changeRemainingTime({
										timeAmount: 'hours',
										operation: 'subtract',
										value,
									})
								}
							>
								{-value}
							</StyledButton>
						))}
					</GridItem>
					<GridItem justifyContent="center" flexDirection="column">
						<Typography variant="h4">{remainingHours}</Typography>
						<Typography variant="body2">{t('timer')}</Typography>
					</GridItem>
					<GridItem justifyContent="flex-start">
						{remainingTimeOptions.hours.reverse().map((value: number) => (
							<StyledButton
								key={value}
								variant="contained"
								color="primary"
								onClick={() =>
									changeRemainingTime({
										timeAmount: 'hours',
										operation: 'add',
										value,
									})
								}
							>
								+{value}
							</StyledButton>
						))}
					</GridItem>

					<GridItem justifyContent="flex-end" flexWrap="wrap">
						{(isMobile
							? remainingTimeOptions.minutes.irregular.reverse()
							: remainingTimeOptions.minutes.regular
						).map((value: number) => (
							<StyledButton
								key={value}
								variant="contained"
								color="primary"
								onClick={() =>
									changeRemainingTime({
										timeAmount: 'min',
										operation: 'subtract',
										value,
									})
								}
							>
								{-value}
							</StyledButton>
						))}
					</GridItem>
					<GridItem justifyContent="center" flexDirection="column">
						<Typography variant="h4">{remainingMinutes}</Typography>
						<Typography variant="body2">{t('minutter')}</Typography>
					</GridItem>
					<GridItem justifyContent="flex-start" flexWrap="wrap">
						{remainingTimeOptions.minutes.regular
							.reverse()
							.map((value: number) => (
								<StyledButton
									key={value}
									variant="contained"
									color="primary"
									onClick={() =>
										changeRemainingTime({
											timeAmount: 'min',
											operation: 'add',
											value,
										})
									}
								>
									+{value}
								</StyledButton>
							))}
					</GridItem>
				</RemainingTimeWrapper>

				<Typography variant="subtitle1">
					{t('Sluttidspunkt')}:{' '}
					<b>{endTimeEstimated && format(endTimeEstimated, 'HH:mm')}</b>{' '}
					{endTimeEstimated && showDate && format(endTimeEstimated, 'dd.MM.yy')}
				</Typography>

				{estimateType === 'estimateTime' && (
					<>
						<br />
						<br />
						<Typography variant="subtitle1">
							{t('Hvor mange gram printer du?')}
						</Typography>
						<EstimatedGramsWrapper>
							<GridItem justifyContent="flex-end">
								{estimatedGramsOptions.map((value: number) => (
									<StyledButton
										variant="contained"
										color="primary"
										onClick={() =>
											setEstimatedGrams(
												estimatedGrams - value > 0 ? estimatedGrams - value : 0
											)
										}
									>
										{-value}
									</StyledButton>
								))}
							</GridItem>
							<GridItem justifyContent="center" flexDirection="column">
								<Typography variant="h4">{estimatedGrams}</Typography>
								<Typography variant="body2">{t('gram')}</Typography>
							</GridItem>
							<GridItem justifyContent="flex-start">
								{estimatedGramsOptions.reverse().map((value: number) => (
									<StyledButton
										variant="contained"
										color="primary"
										onClick={() => setEstimatedGrams(estimatedGrams + value)}
									>
										+{value}
									</StyledButton>
								))}
							</GridItem>
						</EstimatedGramsWrapper>
					</>
				)}
				<br />
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={handleClose}>
					{t('Avbryt')}
				</Button>
				<Button
					variant="contained"
					color="secondary"
					onClick={handleStartPrint}
					disabled={
						startOrProlongPrintStatus === 'loading' ||
						(remainingHours === 0 && remainingMinutes === 0) ||
						(estimatedGrams === 0 && estimateType === 'estimateTime')
					}
				>
					{estimateType === 'estimateTime' ? 'Start print' : t('Legg til tid')}{' '}
				</Button>
			</DialogActions>
		</StyledDialog>
	);
};

const RemainingTimeWrapper = withTheme(styled.div`
	margin: 1rem 0;
	display: grid;
	grid-template-columns: 1fr 3.5rem 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 1rem;
`);

const EstimatedGramsWrapper = withTheme(styled.div`
	margin: 1rem 0;
	display: grid;
	grid-template-columns: 1fr 3.5rem 1fr;
	gap: 1rem;
`);

interface GridItemProps {
	justifyContent: 'flex-end' | 'flex-start' | 'center';
	alignItems?: 'flex-end' | 'flex-start' | 'center';
	flexDirection?: 'row' | 'column';
	flexWrap?: 'wrap' | 'wrap-reverse' | 'nowrap';
}

const GridItem = withTheme(styled.div<GridItemProps>`
	display: flex;
	flex-direction: ${(props) =>
		props.flexDirection ? props.flexDirection : 'row'};
	align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
	justify-content: ${(props) => props.justifyContent};
	flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : 'nowrap')};
`);

const StyledButton = withTheme(styled(Button)`
	border-radius: 50%;
	margin: 0.2rem;
	padding: 0;
	min-width: 3rem;
	height: 3rem;
	& > span {
		font-size: 1.2rem;
		width: min-content;
	}
`);

export default EstimatePrintTimeDialog;
