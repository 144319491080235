import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	getUserData,
	gramFilamentUsedCurrentMonthSel,
	userIdSel,
} from '../../../redux/User/userSlice';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface GramFilamentUsageProps {}

const GramFilamentUsage: React.FC<GramFilamentUsageProps> = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(userIdSel);
	const gramFilamentUsedCurrentMonth = useAppSelector(
		gramFilamentUsedCurrentMonthSel
	);
	const { t } = useTranslation();

	useEffect(() => {
		userId && dispatch(getUserData());
	}, [dispatch, userId]);

	return (
		<>
			<Typography variant="subtitle1">
				{t('Du har brukt')} <b>{gramFilamentUsedCurrentMonth ?? 0} {t('gram')} </b> 
				{t('filament denne måneden.')}
			</Typography>
		</>
	);
};

export default GramFilamentUsage;
