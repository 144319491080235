import React, { useState } from 'react';
import { LocationBasic } from '../../../redux/Location/locationSlice.model';
import { StyledForm } from '../../../styledcomponents/SimpleForms/SimpleForm';
import { StyledTextField, StyledButton } from './PrinterForm';
import { useTranslation } from 'react-i18next';

interface LocationFormProps {
	handleSubmit: ({ name, ipAddress }: LocationBasic) => void;
}

const LocationForm: React.FC<LocationFormProps> = ({ handleSubmit }) => {
	const [name, setName] = useState<string>('');
	const [ipAddress, setIpAddress] = useState<string>('');
	const { t } = useTranslation();

	return (
		<StyledForm
			onSubmit={(e: React.SyntheticEvent) => {
				e.preventDefault();
				handleSubmit({ name, ipAddress });
			}}
		>
			<StyledTextField
				label={t("Kontor")}
				value={name}
				onChange={(event) => setName(event.target.value)}
			/>
			<StyledTextField
				label={t("IP-adresse")}
				value={ipAddress}
				onChange={(event) => setIpAddress(event.target.value)}
			/>
			<StyledButton type="submit" variant="contained" color="secondary">
				{t("Legg til")}
			</StyledButton>
		</StyledForm>
	);
};

export default LocationForm;
