import {
	Accordion,
	AccordionSummary,
	Box,
	Button,
	Container,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import PrintSuccessfulTag from '../../../general-components/PrintSuccessfulTag';
import { lighten } from '@mui/material';
import { theme } from '../../../theme';
import { PrintStats } from '../../../redux/Stats/stats.models';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimeUtils } from '../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import PrintDetails from './PrintDetails';
import styled from 'styled-components';

interface Props {
	history: PrintStats[] | undefined;
	isAdmin: boolean;
}

const HistoryBox: React.FC<Props> = ({ history, isAdmin }: Props) => {
	const printsToAdd = isAdmin ? 10 : 5;
	const [displayCount, setDisplayCount] = useState(printsToAdd);

	const { t } = useTranslation();

	const showMore = () => {
		setDisplayCount((prevCount) => prevCount + printsToAdd);
	};

	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100%',
			}}
		>
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
				{history &&
					history.slice(0, displayCount).map((print: PrintStats, i: number) => {
						return (
							<HistoryItemAccordion key={i}>
								<HistoryItemAccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Box
										sx={{
											display: 'flex',
											flex: 1,
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										{print.startTime && (
											<Typography flex="1">
												{TimeUtils.dateToTwoStringArray(print.startTime)[0]}{' '}
												<br className="mobile-break" />
												{TimeUtils.dateToTwoStringArray(print.startTime)[1]}
											</Typography>
										)}
										<Typography flex="1">
											{t('Varighet')}: <br className="mobile-break" />
											{TimeUtils.getTimeDifference(
												print.startTime,
												print.endTime
											)}
										</Typography>
										<Typography flex="1">
											{t('Filamentbruk')}: {print.gramFilament}g
										</Typography>
										<Box sx={{ ml: 2 }}>
											<PrintSuccessfulTag
												printSuccessful={print.printSuccessful}
												typographyVariant="h6"
												iconOnly={window.innerWidth < 600 ? true : false}
											></PrintSuccessfulTag>
										</Box>
									</Box>
								</HistoryItemAccordionSummary>
								<PrintDetails print={print} />
							</HistoryItemAccordion>
						);
					})}
			</Box>
			{history && displayCount <= history.length && (
				<Button
					variant="text"
					color="primary"
					onClick={showMore}
					endIcon={<ExpandMoreIcon />}
					sx={{
						[theme.breakpoints.down('sm')]: {
							mt: '0.5rem',
						},
					}}
				>
					{t('Vis flere')}
				</Button>
			)}
		</Container>
	);
};

const HistoryItemAccordion = styled(Accordion)`
	box-shadow: none;
	&:before {
		height: 0;
	}
	&.Mui-expanded {
		margin: 0;
	}
	margin-bottom: 0.8rem;
	${theme.breakpoints.down('sm')} {
		margin-bottom: 0.5rem;
	}
`;

const HistoryItemAccordionSummary = styled(AccordionSummary)`
	background-color: ${lighten(theme.palette.grey[900], 0.1)};
	box-shadow: ${theme.shadows[8]};
	border-radius: 10px;
	padding: 0 1.5rem;
	color: ${theme.palette.grey[200]};
	${theme.breakpoints.down('sm')} {
		padding: 0 1rem;
	}
`;

export default HistoryBox;
