import {
	EnqueuedUsersResponse,
	StartOrProlongPrintInput,
} from '../redux/Queue/queueSlice.models';
import {
	QueueItemObj,
	GetCompletedEnqueuedUsersRequest,
	CompletedEnqueuedUserResponse,
	EnqueuedUser,
} from './../models/Queue.models';
import axios from './api.config';

export namespace QueueApi {
	export const getEnqueuedUsers = async (
		printerId: number | null
	): Promise<EnqueuedUsersResponse[]> => {
		const response = await axios.get<EnqueuedUsersResponse[]>(
			`QueueItems/get_enqueued_users`,
			{
				params: {
					printerId: printerId,
				},
			}
		);
		return response.data;
	};

	export const clearQueue = async (printerId: number): Promise<void> => {
		const response = await axios.delete(`QueueItems/clear/${printerId}`);
		return response.data;
	};

	export const enqueueCurrentUser = async (
		userId: number,
		printerId: number
	): Promise<QueueItemObj> => {
		const response = await axios.post<QueueItemObj>(
			`QueueItems/post_in_queue`,
			{ userId: userId, printerId: printerId }
		);
		return response.data;
	};

	export const enqueueCurrentUserVIP = async (
		userId: number,
		printerId: number,
		customer: string,
		project: string
	): Promise<QueueItemObj> => {
		const response = await axios.post<QueueItemObj>(`QueueItems/post_vip`, {
			userId: userId,
			printerId: printerId,
			customer: customer,
			project: project,
		});
		return response.data;
	};

	export const unenqueueCurrentUser = async (
		userId: number
	): Promise<QueueItemObj> => {
		const response = await axios.delete<QueueItemObj>(`QueueItems/delete`, {
			data: {
				userId: userId,
			},
		});
		return response.data;
	};

	export const startOrProlongPrint = async ({
		userId,
		printDurationSec,
		gramFilament,
	}: StartOrProlongPrintInput): Promise<QueueItemObj> => {
		const response = await axios.put<QueueItemObj>(
			'QueueItems/start_or_prolong_print',
			{
				printDurationSec: printDurationSec,
				userId: userId,
				gramFilament,
			}
		);
		return response.data;
	};

	export const moveBackwardsInQueue = async (
		userId: number
	): Promise<QueueItemObj> => {
		const response = await axios.put('QueueItems/postpone_print', {
			userId: userId,
		});
		return response.data;
	};

	export const addPreparationTime = async (queueItemId: number): Promise<QueueItemObj> => {
		const response = await axios.put<QueueItemObj>(
			`QueueItems/add_preparation_time`, { queueItemId }
		);
		return response.data;
	};
	

	export const deleteFirstUserInQueue = async (
		printerId: number | null,
		printSuccessful: boolean
	): Promise<any> => {
		return await axios.delete<void>(`QueueItems/delete_first`, {
			data: { printerId: printerId, printSuccessful: printSuccessful },
		});
	};

	export const fetchCompletedEnqueuedUsers = async (
		getCompletedEnqueuedUsersRequest: GetCompletedEnqueuedUsersRequest
	): Promise<CompletedEnqueuedUserResponse[]> => {
		const response = await axios.get<CompletedEnqueuedUserResponse[]>(
			'UserStatistics/get_last_n_prints_for_printer',
			{
				params: {
					printerId: getCompletedEnqueuedUsersRequest.printerId,
					N: getCompletedEnqueuedUsersRequest.numberOfUsers,
				},
			}
		);
		return response.data;
	};

	export const getUserInQueueStatus = async (
		userId: number
	): Promise<EnqueuedUser> => {
		const response = await axios.get<EnqueuedUser>(
			`QueueItems/get_queueitem_${userId}`,
			{ params: { userId: userId } }
		);
		return response.data;
	};

	export const restartPrint = async (
		userId: number,
		printDurationSec: number,
		numberOfRestarts: number
	): Promise<QueueItemObj> => {
		const response = await axios.put<QueueItemObj>('QueueItems/restart_print', {
			userId: userId,
			printDurationSec: printDurationSec,
			numberOfRestarts: numberOfRestarts,
		});
		return response.data; 
	};
}
