import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	createApi,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { api_url } from './api.config';
import { RootState } from '../redux/store';
import {
	AggregateStats,
	AggregateStatsRequest,
	PrintStats,
	PrintStatsResponse,
} from '../redux/Stats/stats.models';
import { TimeUtils } from '../utils/time.utils';

const parseISODateToData = (data: PrintStatsResponse[]) => {
	return data.map(
		(item: PrintStatsResponse) =>
			({
				...item,
				startTime: TimeUtils.parseISODate(item.startTime),
				endTime: TimeUtils.parseISODate(item.endTime),
				originalEndTime: TimeUtils.parseISODate(item.originalEndTime),
				firstInQueueTime: TimeUtils.parseISODate(item.firstInQueueTime),
				finishedTime: TimeUtils.parseISODate(item.finishedTime),
				enqueuedTime: TimeUtils.parseISODate(item.enqueuedTime),
			} as PrintStats)
	);
};

const baseQuery = fetchBaseQuery({
	baseUrl: api_url + '/UserStatistics',
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).user.jwtToken;
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		window.location.replace('/login');
	}
	return result;
};
	
export const statsApi = createApi({
	reducerPath: 'statsApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (build) => ({
		getUserPrintsForPeriod: build.query<PrintStats[], AggregateStatsRequest>({
			query: (payload) => ({
				url: '/get_user_prints_for_period',
				method: 'POST',
				body: payload,
			}),
			transformResponse: (res: PrintStatsResponse[]) => parseISODateToData(res),
		}),

		getAllPrintsForPeriod: build.query<PrintStats[], AggregateStatsRequest>({
			query: (payload) => ({
				url: '/get_prints_for_period',
				method: 'POST',
				body: payload,
			}),
			transformResponse: (res: PrintStatsResponse[]) => parseISODateToData(res),
		}),

		getUserAggregateStats: build.query<AggregateStats, AggregateStatsRequest>({
			query: (payload) => ({
				url: '/get_user_aggregate_stats',
				method: 'POST',
				body: payload,
			}),
		}),

		getAverageStats: build.query<AggregateStats, AggregateStatsRequest>({
			query: (payload) => ({
				url: '/get_aggregate_stats',
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

export const {
	useGetUserAggregateStatsQuery,
	useGetUserPrintsForPeriodQuery,
	useGetAverageStatsQuery,
	useGetAllPrintsForPeriodQuery,
} = statsApi;
