import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
	text-decoration: none;
	display: inline-block;
	color: inherit;

	&:visited,
	:active {
		color: inherit;
	}
	margin: 0 0.25rem;
`;

export const StyledNavLink = styled(NavLink)`
	text-decoration: none;
	display: inline-block;
	color: inherit;
	&:visited,
	:active {
		color: inherit;
	}
	margin: 0 0.3rem;
`;
