import React, { ReactElement } from 'react';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
} from '@mui/material';
import { withTheme } from '@mui/styles';
import styled from 'styled-components';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useTranslation } from 'react-i18next';

interface AdminTableProps {
	titles: string[];
	rows: ReactElement[];
}

const AdminTable: React.FC<AdminTableProps> = ({ titles, rows }) => {
	const { t } = useTranslation();

	return (
		<TableWrapper>
			<StyledTableContainer as={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{titles[0]}</TableCell>
							{titles.slice(1).map((title) => {
								if (title === 'Gram') {
									return (
										<FlexTableCell key={title} align="right">
											{t('Gram')}
											<Tooltip title="Gram filament brukt denne måneden">
												<ContactSupportIcon fontSize="small" />
											</Tooltip>
										</FlexTableCell>
									);
								}
								return (
									<TableCell key={title} align="right">
										{title}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>{rows}</TableBody>
				</Table>
			</StyledTableContainer>
		</TableWrapper>
	);
};

export const StyledTableContainer = styled(TableContainer)`
	max-width: 960px;
`;

export const TableWrapper = withTheme(styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 40px 0;
`);

export const FlexTableCell = styled(TableCell)`
	display: flex;
	align-items: center;
`;

export default AdminTable;
