import { Dialog } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';

export const StyledDialog = withTheme(styled(Dialog)`
	${(props) => props.theme.breakpoints.down('sm')} {
		& .MuiPaper-root {
			margin: 1rem 0.2rem;
		}
	}
`);
