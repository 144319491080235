import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import React from 'react';
import styled from 'styled-components';
import RegisterUserForm from './components/RegisterUserForm'
import { useTranslation } from 'react-i18next';

export interface RegisterPageProps {

}

const RegisterPage: React.FC<RegisterPageProps> = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <RegisterPageWrapper>
            <GridItem>
                <FontAwesomeIcon icon={faUserPlus} size="10x" color={theme.palette.secondary.dark} />
            </GridItem>
            <GridItem>
                <Typography variant="h3">{t('Registrer ny bruker')}</Typography>
                <RegisterUserForm />
            </GridItem>
        </RegisterPageWrapper>
    );
}


const RegisterPageWrapper = withTheme(styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: auto;
   
`)

const GridItem = withTheme(styled.div`
    min-height: 15rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
`);
export default RegisterPage;