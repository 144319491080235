import AppBar from '@mui/material/AppBar';
import { Container, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCube } from '@fortawesome/free-solid-svg-icons';
import { StyledLink } from '../styledcomponents/ReactRouter/ReactRouter';
import { useState } from 'react';
import SideNavbar from './SideNavbar';
import NavbarLinks from './NavbarLinks';
import { useAppSelector } from '../redux/hooks';
import { onTheLabSel } from '../redux/Location/locationSlice';

const Navbar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const onTheLab = useAppSelector(onTheLabSel);

	const [showSidenav, setShowSidenav] = useState<boolean>(false);

	return (
		<AppBar position="fixed">
			<Container>
				<StyledToolBar>
					<StyledLink to={`/${onTheLab ? 'lab' : ''}`}>
						<Typography variant="h6">
							<FontAwesomeIcon icon={faCube} style={{ marginRight: '.5rem' }} />
							LabQ
						</Typography>
					</StyledLink>

					<FloatRightWrapper>
						{isMobile ? (
							<StyledIconButton onClick={() => setShowSidenav(true)}>
								<FontAwesomeIcon icon={faBars} />
							</StyledIconButton>
						) : (
							<NavbarLinks />
						)}
					</FloatRightWrapper>
				</StyledToolBar>
			</Container>
			<SideNavbar open={showSidenav} setShowSidenav={setShowSidenav} />
		</AppBar>
	);
};

const StyledToolBar = withTheme(styled(Toolbar)`
	display: flex;
	justify-content: space-between;
`);

const FloatRightWrapper = withTheme(styled.div`
	display: flex;
	align-items: center;
`);

const StyledIconButton = withTheme(styled(IconButton)`
	color: inherit;
`);

export default Navbar;
