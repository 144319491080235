import {
	Box,
	Container,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { changeLanguage } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import norwegianFlag from '../images/Flag_of_Norway.png';
import britishFlag from '../images/Flag_of_the_United_Kingdom.png';

const SelectLanguage: FC = () => {
	const { i18n } = useTranslation();
	const languages = ['en', 'no'];

	const handleChange = (e: SelectChangeEvent): void => {
		changeLanguage(e.target.value);
	};

	const renderFlag = (language: string) => {
		return (
			<Box
				sx={{
					backgroundImage:
						language === 'en' ? `url(${britishFlag})` : `url(${norwegianFlag})`,
					width: 32,
					height: language === 'no' ? 23 : 19,
					backgroundSize: 'cover',
				}}
			/>
		);
	};

	return (
		<Box>
			<FormControl size="small">
				<Select
					onChange={handleChange}
					value={i18n.language}
					renderValue={() => renderFlag(i18n.language)}
					defaultValue={i18n.language}
					MenuProps={{
						PaperProps: {
							sx: PaperPropsStyle,
						},
					}}
					sx={SelectStyle}
				>
					{languages.map((language) => (
						<MenuItem value={language} key={language} sx={{ pl: 0, pr: 0 }}>
							<Container sx={{ display: 'flex', alignItems: 'center' }}>
								{renderFlag(language)}
								<Typography sx={{ ml: 2 }}>{language.toUpperCase()}</Typography>
							</Container>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

const SelectStyle = {
	'& .MuiSvgIcon-root': {
		color: 'white',
	},
	'.MuiOutlinedInput-input': {
		display: 'flex',
		alignItems: 'center',
	},
};

const PaperPropsStyle = {
	'& .MuiMenuItem-root.Mui-selected': {
		backgroundColor: 'rgba(17, 19, 60, 0.9)',
		color: 'white',
		'&.MuiMenuItem-root.Mui-focusVisible': {
			background: 'rgba(17, 19, 60, 0.9)',
			color: 'white',
		},
		'&.MuiMenuItem-root.Mui-selected:hover': {
			backgroundColor: '#11133C',
			color: 'white',
		},
	},
	'& .MuiMenuItem-root:hover': {
		backgroundColor: 'rgba(17, 19, 60, 0.8)',
		color: 'white',
	},
	'& .MuiMenuItem-root.Mui-selected:hover': {
		backgroundColor: '#11133C',
		color: 'white',
	},
};

export default SelectLanguage;
