import { Button, Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CompletedEnqueuedUser } from '../../../models/Queue.models';
import { useAppSelector } from '../../../redux/hooks';
import { completedEnqueuedUsersSel } from '../../../redux/Queue/queueSlice';
import QueueItem from './QueueItem';
import { QueueListWrapper } from './QueueList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';

export interface PreviousPrintsListProps {}

const CompletedPrintsList: React.FC<PreviousPrintsListProps> = () => {
	const completedEnqueuedUsers = useAppSelector(completedEnqueuedUsersSel);

	const [showMoreCompletedPrints, setShowMoreCompletedPrints] =
		useState<boolean>(false);

	const { t } = useTranslation();

	const handleShowMoreCompletedPrintsToggle = () =>
		setShowMoreCompletedPrints(!showMoreCompletedPrints);

	return (
		<Wrapper>
			<Typography variant="h5" gutterBottom paragraph>
				{t('Ferdige printer')}
			</Typography>
			<QueueListWrapper>
				{completedEnqueuedUsers
					.slice(0, showMoreCompletedPrints ? 5 : 1)
					.map((item: CompletedEnqueuedUser) => (
						<QueueItem enqueuedUser={item} key={item.queueItemId} onTheLab={false} />
					))}
			</QueueListWrapper>

			<Button
				variant="text"
				color="primary"
				onClick={handleShowMoreCompletedPrintsToggle}
				endIcon={
					showMoreCompletedPrints ? <ExpandLessIcon /> : <ExpandMoreIcon />
				}
			>
				{showMoreCompletedPrints ? t('Vis færre printer') : t('Vis flere printer')}
			</Button>
		</Wrapper>
	);
};

const Wrapper = withTheme(styled.div`
	display: grid;
	place-items: center;
	width: 100%;
`);

export default CompletedPrintsList;
