import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateUser, updateUserStatusSel } from '../../../redux/User/userSlice';
import {
	ErrorTypography,
	FormWrapper,
	StyledForm,
	StyledTextField,
} from '../../../styledcomponents/SimpleForms/SimpleForm';
import { ActiveForm } from '../ProfilePage';
import { useSnackbar } from 'notistack';
import { UserRequestBody } from '../../../redux/User/userSlice.models';
import { useTranslation } from 'react-i18next';

export interface RegisterUserFormProps {
	changeFormCallback: (activeForm: ActiveForm) => void;
}

type FormInputs = {
	prevPinCode: string;
	newPinCode: string;
	confirmNewPinCode: string;
};

const ChangePasswordForm: React.FC<RegisterUserFormProps> = ({
	changeFormCallback,
}) => {
	const dispatch = useAppDispatch();
	const updateUserStatus = useAppSelector(updateUserStatusSel);

	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleChangeForm = () => changeFormCallback('profile');

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<FormInputs>();

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		if (data.newPinCode !== data.confirmNewPinCode) {
			setError('confirmNewPinCode', {
				type: 'manual',
				message: t('PIN-kodene er ikke like. Prøv på nytt'),
			});
			return;
		}

		const userChanges: UserRequestBody = {
			oldPassword: data.prevPinCode.trim(),
			password: data.newPinCode.trim(),
			confirmPassword: data.confirmNewPinCode.trim(),
		};

		try {
			await dispatch(updateUser(userChanges)).unwrap();
			changeFormCallback('profile');
			enqueueSnackbar(t(`Passord er endret`), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (err) {
			enqueueSnackbar(t(`Kunne ikke endre PIN-kode. Prøv på nytt`), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<StyledForm noValidate autoComplete="off">
				<Typography variant="h6">{t('Endre PIN-kode')}</Typography>

				<StyledTextField
					{...register('prevPinCode', {
						required: t('PIN-kode er påkrevd. Prøv på nytt'),
					})}
					id="prevPinCode"
					color="secondary"
					label={t('Gjeldende PIN-kode')}
					type="password"
					variant="outlined"
				/>
				{errors.prevPinCode && (
					<ErrorTypography>{errors.prevPinCode.message}</ErrorTypography>
				)}
				<br />

				<Typography variant="body2">
					{t('Velg en PIN-kode som består av 4 tall')}
				</Typography>
				<StyledTextField
					{...register('newPinCode', {
						required: t('PIN-kode er påkrevd. Prøv på nytt'),
					})}
					id="pinCode"
					color="secondary"
					label={t('Ny PIN-kode')}
					type="password"
					variant="outlined"
				/>
				{errors.newPinCode && (
					<ErrorTypography>{errors.newPinCode.message}</ErrorTypography>
				)}

				<StyledTextField
					{...register('confirmNewPinCode', {
						required: t('PIN-kode er påkrevd. Prøv på nytt'),
					})}
					id="confirmNewPinCode"
					color="secondary"
					label={t('Gjenta ny PIN-kode')}
					type="password"
					variant="outlined"
				/>
				{errors.confirmNewPinCode && (
					<ErrorTypography>{errors.confirmNewPinCode.message}</ErrorTypography>
				)}

				<br />
				<Button
					endIcon={
						updateUserStatus === 'loading' ? (
							<CircularProgress size={20} />
						) : (
							<FontAwesomeIcon icon={faCheck} />
						)
					}
					type="submit"
					variant="contained"
					color="secondary"
					id="submit"
					disabled={updateUserStatus === 'loading'}
				>
					{t('Oppdater PIN-kode')}
				</Button>
			</StyledForm>
			<br />
			<br />

			<Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
				{t('Vil du endre profil?')}{' '}
				<Button onClick={handleChangeForm} variant="outlined">
					{t('Endre profil')}
				</Button>
			</Typography>
			<br />
		</FormWrapper>
	);
};

export default ChangePasswordForm;
