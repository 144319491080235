import { CircularProgress } from '@mui/material';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	currentUserInPrinterIdSel,
	enqueueCurrentUserStatusSel,
	enqueuedUsersStatusSel,
	enqueueUser,
	refreshPrinterAndQueue,
} from '../../../redux/Queue/queueSlice';
import { useSnackbar } from 'notistack';
import { MainQueueActionButton } from '../../../general-components/Button';
import { userIdSel } from '../../../redux/User/userSlice';
import { useTranslation } from 'react-i18next';
import { StyledForm } from '../../../styledcomponents/SimpleForms/SimpleForm';
import { StyledDiv } from '../../AdminPage/components/PrintersTable';

const QueueActionsPrivate = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(userIdSel);
	const enqueueCurrentUserStatus = useAppSelector(enqueueCurrentUserStatusSel);
	const currentUserInPrinterId = useAppSelector(currentUserInPrinterIdSel);
	const enqueuedUsersStatus = useAppSelector(enqueuedUsersStatusSel);

	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleEnqueueClick = async () => {
		try {
			if (!userId) {
				throw new Error();
			}
			await dispatch(enqueueUser(userId)).unwrap();
			dispatch(refreshPrinterAndQueue());

			enqueueSnackbar(t('Du er lagt til i kø!'), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (e) {
			enqueueSnackbar(t('Noe gikk galt! Prøv på nytt.'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	return (
		<>
			<StyledForm onSubmit={handleEnqueueClick}>
				<StyledDiv>
					
				
				<MainQueueActionButton
					type="submit"
					variant="contained"
					color="secondary"
					disabled={
						enqueueCurrentUserStatus === 'loading' ||
						currentUserInPrinterId > 0 ||
						enqueuedUsersStatus === 'loading'
					}
					endIcon={
						enqueueCurrentUserStatus === 'loading' ? (
							<CircularProgress size={20} />
						) : (
							<AddToQueueIcon />
						)
					}
				>
					{t('Still meg i kø')}
				</MainQueueActionButton>
				</StyledDiv>
			</StyledForm>
		</>
	);
};
export default QueueActionsPrivate;
