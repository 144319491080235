import { useState } from 'react';
import { Paper, Tabs, Tab } from '@mui/material';

import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import PrintIcon from '@mui/icons-material/Print';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import LocationsTable from './components/LocationsTable';
import UsersTable from './components/UsersTable';
import PrintersTable from './components/PrintersTable';
import { useAppSelector } from '../../redux/hooks';
import { roleSel } from '../../redux/User/userSlice';
import { useTranslation } from 'react-i18next';
import DashboardPage from '../DashboardPage/DashboardPage';
import StatisticsTable from './components/StatisticsTable';

const AdminPage = () => {
	const [value, setValue] = useState<number>(0);
	const role = useAppSelector(roleSel);
	const { t } = useTranslation();

	const isSuperAdmin = role === 'SuperAdmin';

	return (
		<>
			<Paper square>
				<Tabs
					onChange={(_, newValue) => setValue(newValue)}
					value={value}
					centered
				>
					<Tab icon={<PrintIcon />} label={t('Printere')} />
					<Tab icon={<PeopleIcon />} label={t('Brukere')} />
					{isSuperAdmin && (
						<Tab icon={<BusinessIcon />} label={t('Kontorer')} />
					)}
					<Tab icon={<AssessmentOutlinedIcon />} label={t('Dashboard')} />
					<Tab icon={<QueryStatsIcon />} label={t('Innsikt')} />
				</Tabs>
			</Paper>
			{value === 0 && <PrintersTable />}
			{value === 1 && <UsersTable />}
			{value === 2 && isSuperAdmin && <LocationsTable />}
			{value === 3 && <DashboardPage isAdmin={true} />}
			{value === 4 && <StatisticsTable />}
			<br />
		</>
	);
};

export default AdminPage;
