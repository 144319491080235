import {
	ClickAwayListener,
	Modal,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { ReserveButton } from '../../../general-components/Button';
import { useTranslation } from 'react-i18next';
import { QueueState } from '../../../models/Queue.models';
import { onTheLabSel } from '../../../redux/Location/locationSlice';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import {
	currentUserInPrinterIdSel,
	enqueueCurrentUserStatusSel,
	enqueuedUsersStatusSel,
	firstUserInQueueSel,
} from '../../../redux/Queue/queueSlice';
import { userIdSel } from '../../../redux/User/userSlice';
import { useAppSelector } from '../../../redux/hooks';
import PrintIsFinished from './PrintIsFinished';
import { Link } from 'react-router-dom';
import {
	StyledDiv,
	StyledPaper,
} from '../../AdminPage/components/PrintersTable';
import QueueActionsPrivate from './QueueActionsPrivate';
import { Help } from '@mui/icons-material';
import QueueActionsVIP from './QueueActionsVIP';
import { RootState } from '../../../redux/store';

const MainQueueActions= () => {

	const [modalOpen, setModalOpen] = useState(false);
	const [vipMode, setVipMode] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);

	const userId = useAppSelector(userIdSel);
	const enqueueCurrentUserStatus = useAppSelector(enqueueCurrentUserStatusSel);
	const currentUserInPrinterId = useAppSelector(currentUserInPrinterIdSel);
	const enqueuedUsersStatus = useAppSelector(enqueuedUsersStatusSel);
	const onTheLab = useAppSelector(onTheLabSel);
	const firstUserInQueue = useAppSelector(firstUserInQueueSel);

	const activePrinterState = useAppSelector((state: RootState) =>
		state.printer.printers.find((printer: { id: number; }) => printer.id === state.printer.activePrinterId)?.printerState
	);

	const { t } = useTranslation();

	const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVipMode(event.target.checked);
	};

	const handleModalOpen = () => {
		setModalOpen(true);
	};
	const handleModalClose = () => {
		setModalOpen(false);
	};

	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};
	const handleTooltipOpen = () => {
		setOpenTooltip(true);
	};

	return (
		<>
			{!userId && (
				<Typography variant="subtitle1" style={{ marginBottom: '0.8rem' }}>
					{t('Du må være innlogget for å stille deg i kø.')}{' '}
					<Link to="/login">{t('Logg inn')}</Link> {t('eller')}{' '}
					<Link to="/register">{t('registrer ny bruker.')}</Link>
				</Typography>
			)}

			{onTheLab &&
				firstUserInQueue &&
				(firstUserInQueue.queueState === QueueState.Printing ||
					firstUserInQueue.queueState === QueueState.Finished) && (
					<PrintIsFinished mainQueueAction={true} user={firstUserInQueue} />
				)}

			{(!firstUserInQueue ||
				(firstUserInQueue && userId && userId !== firstUserInQueue.userId)) &&
				!currentUserInPrinterId && (
					<ReserveButton
						color="secondary"
						variant="contained"
						onClick={handleModalOpen}
						disabled={
							enqueueCurrentUserStatus === 'loading' ||
							currentUserInPrinterId > 0 ||
							enqueuedUsersStatus === 'loading' ||
							activePrinterState === 'Disabled'
						}
					>
						{t('Still meg i kø')}
						<AddToQueueIcon style={{paddingLeft:"10px"}} />
					</ReserveButton>
				)}

			<Modal
				className="myModal"
				open={modalOpen}
				onSubmit={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				onClose={handleModalClose}
				keepMounted={false}
			>
				<StyledPaper>
					<StyledDiv
						style={{
							padding: '1rem',
							marginTop: '25px',
							display: 'grid',
							gridGap: '0.4rem',
							gridAutoFlow: 'row-dense',
						}}
					>
						<StyledDiv style={{ marginBottom: '25px' }}>
							{!vipMode && <h2>{t('Personlig print')}</h2>}
							{vipMode && (
								<>
									<h2>{t('Kundeprosjekt')}</h2>
									<p>{t('Du blir automatisk satt til neste i køen.')}</p>
								</>
							)}
							{!vipMode && (
								<>
									<StyledDiv
										style={{
											paddingTop: '1rem',
											marginTop: '10px',
											flexDirection: 'row',
											gridGap: '0.4rem',
										}}
									>
										<p>{t('Kundeprosjekt?')}</p>

										<ClickAwayListener onClickAway={handleTooltipClose}>
											<Tooltip
												onClose={handleTooltipClose}
												open={openTooltip}
												disableFocusListener
												disableHoverListener
												disableTouchListener
												title={t(
													'Kundeprosjekter prioriteres før personlige printer'
												)}
											>
												<Help
													fontSize="small"
													style={{ marginLeft: '.2rem' }}
													onClick={handleTooltipOpen}
												/>
											</Tooltip>
										</ClickAwayListener>
									</StyledDiv>
								</>
							)}
							<StyledDiv style={{ marginBottom: '25px', marginTop: '1px' }}>
								<Switch checked={vipMode} onChange={handleToggle} />
							</StyledDiv>
						</StyledDiv>
						{!vipMode && <QueueActionsPrivate/>}
						{vipMode && <QueueActionsVIP/>}
					</StyledDiv>
				</StyledPaper>
			</Modal>
		</>
	);
};

export default MainQueueActions;
