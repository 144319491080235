import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useAppDispatch } from '../../../redux/hooks';
import { addPreparationTime } from '../../../redux/Queue/queueSlice';
import { EnqueuedUser } from '../../../models/Queue.models';
import { SuccessButton } from '../../../general-components/Button';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { useTranslation } from 'react-i18next';


interface PreparationButtonProps {
    queueItem: EnqueuedUser;
    disabled: boolean;
}

const PreparationButton = ({ queueItem, disabled }: PreparationButtonProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleConfirm = async () => {
        handleClose();
        if (queueItem && queueItem.queueItemId > 0) {
            await dispatch(addPreparationTime(queueItem.queueItemId));
        } else {
            console.error("Invalid or missing queueItem ID");
        }
    };

    return (
        <>
            <SuccessButton
                variant="contained"
                onClick={handleClickOpen}
                color="primary"
                disabled={disabled}
            >
                {t('Legg til tid ')}
                <ThumbUpAltRoundedIcon style={{marginLeft:"10px"}} />
            </SuccessButton>

            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>{t('Legg til tid: 15 minutter')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Er du sikker på at du vil legge til 15 minutter i forberedelses tiden?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('Avbryt')}
                    </Button>
                    <SuccessButton
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        autoFocus>
                        {t('Legg til')}
                    </SuccessButton>
                    
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PreparationButton;
