import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
	interface Palette {
		tertiary: Palette['primary'];
	}
	interface PaletteOptions {
		tertiary: PaletteOptions['primary'];
	}
}

export const theme = createTheme({
	palette: {
		primary: {
			main: '#11133C',
		},
		secondary: {
			light: '#FCC594',
			main: '#FBAC66',
			dark: '#FC8113',
		},
		tertiary: {
			light: '#43F293',
			main: '#3BD682',
			dark: '#27AB63',
		},
		background: {
			default: '#FCF8F3',
		},
	},
	typography: {
		h1: {
			fontSize: '4.5rem',
		},
		h2: {
			fontSize: '3.5rem',
		},
	},
	spacing: (factor: number) => `${0.25 * factor}rem`,
});
