import { useEffect } from 'react';
import styled from 'styled-components';
import { Container, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { Typography } from '@mui/material';
import QueueList from './components/QueueList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
	showQueueSelector,
	userIdSel,
	firstNameSel,
} from '../../redux/User/userSlice';
import CompletedPrintsSection from './components/CompletedPrintsSection';
import { Alignment } from '../../styledcomponents/General/alignment';
import PrinterButtonList from './components/PrinterButtonList';
import RegisterOrLoginHeroSection from './components/RegisterOrLoginHeroSection';
import { setOnTheLab } from '../../redux/Location/locationSlice';
import GramFilamentUsage from './components/GramFilamentUsage';
import { useTranslation } from 'react-i18next';

interface HomePageProps {
	lab: boolean;
}

const HomePage = ({ lab }: HomePageProps) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const userId = useAppSelector(userIdSel);
	const firstName = useAppSelector(firstNameSel);
	const showQueue = useAppSelector(showQueueSelector);
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setOnTheLab(lab));
	}, [dispatch, lab]);

	return (
		<HomePageWrapper>
			<Container>
				<HeroWrapper>
					<FontAwesomeIcon
						icon={faCube}
						size="10x"
						color={theme.palette.primary.main}
					/>
					<WelcomeWrapper>
						{userId ? (
							<>
								<Typography variant="h3">
									{t('Velkommen')} {firstName}
								</Typography>
								<GramFilamentUsage />
							</>
						) : (
							<>
								<Typography variant="h3">{t('Velkommen til LabQ')}</Typography>
								<Typography variant="subtitle1">
									{t(`Her kan du stille deg i kø for print, bli varslet på sms når
									det er din tur, og se hvilke printere som er ledige til enhver
									tid 🎉`)}
								</Typography>
							</>
						)}
					</WelcomeWrapper>
				</HeroWrapper>
				<>
					<PrinterButtonList />
					<QueueList />
				</>
			</Container>

			<CompletedPrintsSection />

			{!showQueue && <RegisterOrLoginHeroSection />}

			<Alignment.CenterAlignHorVerWrapper style={{ padding: '3rem' }}>
				<Typography variant="body1">
					<i>But we are still waiting in queue </i>
				</Typography>
				<Typography variant="body2">Mark Owen</Typography>
			</Alignment.CenterAlignHorVerWrapper>
		</HomePageWrapper>
	);
};

const HomePageWrapper = withTheme(styled.div`
	height: 100%;
`);

const HeroWrapper = withTheme(styled.div`
	min-height: 24rem;
	display: grid;
	place-items: center;
	grid-template-columns: 1fr 1fr;
	${(props) => props.theme.breakpoints.down('sm')} {
		grid-template-columns: auto;
		padding-top: 2rem;
		text-align: center;
	}
`);

const WelcomeWrapper = withTheme(styled.div`
	display: grid;
	padding-right: 5rem;
	place-items: center flex-start;
	${(props) => props.theme.breakpoints.down('sm')} {
		padding-right: 0;
		place-items: center;
	}
`);

export default HomePage;
