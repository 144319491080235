import React, { useState } from 'react';
import {
	Button,
	CircularProgress,
	ClickAwayListener,
	InputAdornment,
	Tooltip,
	darken,
	useTheme,
} from '@mui/material';
import { Typography } from '@mui/material';
import { faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	deleteUserChain,
	deleteUserStatusSel,
	firstNameSel,
	getUserData,
	lastNameSel,
	locationNameSel,
	phoneNumberSel,
	updateUser,
	updateUserStatusSel,
	userIdSel,
} from '../../../redux/User/userSlice';
import {
	ErrorTypography,
	FormWrapper,
	StyledForm,
	StyledTextField,
} from '../../../styledcomponents/SimpleForms/SimpleForm';
import { useSnackbar } from 'notistack';
import { ActiveForm } from '../ProfilePage';
import { useNavigate } from 'react-router-dom';
import { StringUtils } from '../../../utils/string.utils';
import { UserRequestBody } from '../../../redux/User/userSlice.models';
import { useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { onTheLabSel } from '../../../redux/Location/locationSlice';
import { TypographyIcon } from '../../../styledcomponents/Typography/Typography';
import { Help } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface RegisterUserFormProps {
	changeFormCallback: (activeForm: ActiveForm) => void;
}

type FormInputs = {
	firstName: string;
	lastName: string;
	phoneNumber: string;
};

const ProfileForm: React.FC<RegisterUserFormProps> = ({
	changeFormCallback,
}) => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const locationName = useAppSelector(locationNameSel);
	const updateUserStatus = useAppSelector(updateUserStatusSel);
	const phoneNumberStored = useAppSelector(phoneNumberSel);
	const firstNameStored = useAppSelector(firstNameSel);
	const lastNameStored = useAppSelector(lastNameSel);
	const deleteUserStatus = useAppSelector(deleteUserStatusSel);
	const confirm = useConfirm();
	const onTheLab = useAppSelector(onTheLabSel);
	const userId = useAppSelector(userIdSel);
	const { t } = useTranslation();

	const [openTooltip, setOpenTooltip] = useState(false);

	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};

	const handleTooltipOpen = () => {
		setOpenTooltip(true);
	};

	const handleChangeForm = () => changeFormCallback('password');

	const {
		handleSubmit,
		setValue,
		formState: { errors },
		control,
	} = useForm<FormInputs>();

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		const userChanges: UserRequestBody = {};

		data.firstName.trim() !== firstNameStored &&
			(userChanges.firstName = data.firstName.trim());
		data.lastName.trim() !== lastNameStored &&
			(userChanges.lastName = data.lastName.trim());

		if (
			!userChanges.firstName &&
			!userChanges.lastName &&
			!userChanges.phoneNumber
		) {
			enqueueSnackbar(
				t(`Du har ikke endret noe av profilinformasjonen din. Endre profilinformasjon og trykk deretter på lagre.`),
				{
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
			return;
		}

		try {
			await dispatch(updateUser(userChanges)).unwrap();

			enqueueSnackbar(t(`Profil er oppdatert`), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (err) {
			// unsuccessfull registration
			enqueueSnackbar(t(`Oppdatering av profil feilet. Prøv på nytt`), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	const handleDeleteUser = async (): Promise<void> => {
		await confirm({
			title: t('Er du sikker?'),
			description: t(`Dette vil slette brukeren din`),
			confirmationText: t('Slett bruker'),
			confirmationButtonProps: {
				color: 'secondary',
				variant: 'contained',
				style: { background: theme.palette.error.main, color: 'white' },
			},
			cancellationText: t('Avbryt'),
		});
		try {
			await dispatch(deleteUserChain(userId ?? 0));
			navigate(`/${onTheLab ? 'lab' : ''}`);
			enqueueSnackbar(t(`Brukeren din er slettet`), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (e) {
			enqueueSnackbar(
				t(`Kunne ikke slette brukeren din. Dersom du allerede står i kø
				må du gå ut av køen, og prøv deretter å slette brukeren din på nytt.`),
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
		}
	};

	useEffect(() => {
		dispatch(getUserData());
	}, [dispatch]);

	useEffect(() => {
		firstNameStored && setValue('firstName', firstNameStored);
	}, [firstNameStored, setValue]);

	useEffect(() => {
		lastNameStored && setValue('lastName', lastNameStored);
	}, [lastNameStored, setValue]);

	useEffect(() => {
		phoneNumberStored &&
			setValue(
				'phoneNumber',
				StringUtils.removePhoneCode(phoneNumberStored ?? '')
			);
	}, [phoneNumberStored, setValue]);

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<TypographyIcon variant="subtitle1" justifyContent="flex-start">
				{t('Kontor:')}
				<b
					style={{
						color: darken(theme.palette.primary.dark, 0),
						marginLeft: '0.3rem',
					}}
				>
					{locationName}
				</b>
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<Tooltip
						onClose={handleTooltipClose}
						open={openTooltip}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						title={t("Vi finner ditt kontor ved å se på hvilken IP-addresse du bruker.")}
					>
						<Help
							fontSize="small"
							style={{ marginLeft: '.2rem' }}
							onClick={handleTooltipOpen}
						/>
					</Tooltip>
				</ClickAwayListener>
			</TypographyIcon>
			<StyledForm noValidate autoComplete="off">
				<Typography variant="h6">{t('Endre profilinformasjon')}</Typography>

				<Controller
					render={({ field }) => (
						<StyledTextField
							{...field}
							id="firstName"
							InputLabelProps={{ shrink: true }}
							color="secondary"
							label={t("Fornavn")}
							placeholder="Ola"
							variant="outlined"
						/>
					)}
					defaultValue=''
					name="firstName"
					control={control}
					rules={{
						required: t('Fornavn er påkrevd. Skriv inn fornavn.'),
						maxLength: 50,
						minLength: 2,
					}}
				/>

				{errors.firstName && (
					<ErrorTypography>{errors.firstName.message}</ErrorTypography>
				)}

				<Controller
					render={({ field }) => (
						<StyledTextField
							{...field}
							id="lastName"
							InputLabelProps={{ shrink: true }}
							color="secondary"
							label={t("Etternavn")}
							placeholder="Nordmann"
							variant="outlined"
						/>
					)}
					defaultValue=''
					name="lastName"
					control={control}
					rules={{
						required: t('Etternavn er påkrevd. Skriv inn etternavn.'),
						maxLength: 50,
						minLength: 2,
					}}
				/>

				{errors.lastName && (
					<ErrorTypography>{errors.lastName.message}</ErrorTypography>
				)}

				<Controller
					render={({ field }) => (
						<StyledTextField
							disabled
							{...field}
							id="phoneNumber"
							InputLabelProps={{ shrink: true }}
							color="secondary"
							label="Telefonnnummer"
							placeholder="987 65 432"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">+47</InputAdornment>
								),
							}}
						/>
					)}
					defaultValue=''
					name="phoneNumber"
					control={control}
					rules={{
						required:
							t('Telefonnummer er påkrevd. Skriv inn telefonnummeret ditt.'),
						maxLength: 8,
						minLength: 8,
					}}
				/>

				{errors.phoneNumber && (
					<ErrorTypography>{errors.phoneNumber.message}</ErrorTypography>
				)}

				<br />
				<Button
					endIcon={
						updateUserStatus === 'loading' ? (
							<CircularProgress size={20} />
						) : (
							<FontAwesomeIcon icon={faCheck} />
						)
					}
					type="submit"
					variant="contained"
					color="secondary"
					id="submit"
					disabled={updateUserStatus === 'loading'}
				>
					{t('Lagre')}
				</Button>
			</StyledForm>
			<br /> <br />
			<Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
				{t('Ny PIN-kode?')}{'  '}
				<Button onClick={handleChangeForm} variant="outlined">
					{t('Endre PIN-kode')}
				</Button>
			</Typography>
			<br />
			<Button
				variant="outlined"
				fullWidth
				onClick={handleDeleteUser}
				style={{
					color: theme.palette.error.main,
					borderColor: theme.palette.error.main,
				}}
				endIcon={
					deleteUserStatus === 'loading' ? (
						<CircularProgress size={20} />
					) : (
						<FontAwesomeIcon icon={faTrashAlt} />
					)
				}
				disabled={deleteUserStatus === 'loading'}
			>
				{t('Slett bruker')}
			</Button>
			<br /> <br />
		</FormWrapper>
	);
};

export default ProfileForm;
