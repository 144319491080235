import { useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	TableRow,
	TableCell,
	IconButton,
	lighten,
	TextField,
	Button,
} from '@mui/material';
import { Location } from '../../../../redux/Location/locationSlice.model';
import styled from 'styled-components';
import { useAppDispatch } from '../../../../redux/hooks';
import { updateIpAddress } from '../../../../redux/Location/locationSlice';
import { useSnackbar } from 'notistack';
import { successSnack } from '../LocationsTable';
import { useTranslation } from 'react-i18next';

interface LocationRowProps {
	location: Location;
	deleteLocation: (id: number) => void;
	deleteColor: string;
}

const checkIfValidIP = (ipAddress: string) => {
	return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
		ipAddress
	);
};

const LocationRow = ({
	location,
	deleteLocation,
	deleteColor,
}: LocationRowProps) => {
	const [ipAddress, setIpAddress] = useState<string>(location.ipAddress);
	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleUpdateIpAddress = async () => {
		try {
			if (!checkIfValidIP(ipAddress)) {
				return enqueueSnackbar(t('IP-adressen er ikke gyldig.'), {
					...successSnack,
					variant: 'error',
				});
			}
			await dispatch(updateIpAddress({ locationId: location.id, ipAddress }));
			enqueueSnackbar(t('IP-adresse oppdatert.'), successSnack);
		} catch (error) {
			enqueueSnackbar(t('Noe gikk galt. Kunne ikke oppdatere.'), {
				...successSnack,
				variant: 'error',
			});
		}
	};

	return (
        (<TableRow key={location.id}>
            <TableCell component="th" scope="row">
				{location.name}
			</TableCell>
            <TableCell align="right">
				<TextField
					value={ipAddress}
					onChange={(e) => setIpAddress(e.target.value as string)}
					slotProps={{
                        htmlInput: { style: { textAlign: 'end' } }
                    }}
				/>
				{ipAddress !== location.ipAddress && (
					<StyledButton onClick={handleUpdateIpAddress}>{("Lagre")}</StyledButton>
				)}
			</TableCell>
            <TableCell align="right">
				<IconButton
					disabled={location.locationState === 'InUse'}
					onClick={() => deleteLocation(location.id)}
					size="small"
				>
					<FontAwesomeIcon
						icon={faTrashAlt}
						color={
							location.locationState === 'InUse'
								? lighten(deleteColor, 0.6)
								: deleteColor
						}
					/>
				</IconButton>
			</TableCell>
        </TableRow>)
    );
};

const StyledButton = styled(Button)`
	position: absolute;
`;

export default LocationRow;
