import { useState, useEffect } from 'react';
import AdminTable from './AdminTable';
import { PrinterInput } from '../../../redux/Printer/printeSlice.model';
import PrintIcon from '@mui/icons-material/Print';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Paper, Typography, Modal } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { TypographyIcon } from '../../../styledcomponents/Typography/Typography';
import PrinterForm from './PrinterForm';
import {
	getLocations,
	locationsSel,
} from '../../../redux/Location/locationSlice';
import { locationSel, roleSel } from '../../../redux/User/userSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import SelectLocation from './SelectLocation';
import PrinterRow from './rows/PrinterRow';
import {
	printersSel,
	getAllPrinters,
	deletePrinter,
	updatePrinter,
	createPrinter,
	getAllPrintersOnLocation,
} from '../../../redux/Printer/printerSlice';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';


const PrintersTable = () => {
	const confirm = useConfirm();
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const [showModal, setShowModal] = useState(false);
	const [locationFilter, setLocationFilter] = useState(-1);
	const locations = useAppSelector(locationsSel);
	const role = useAppSelector(roleSel);
	const locationId = useAppSelector(locationSel);
	const printers = useAppSelector(printersSel);
	const { t } = useTranslation();

	const titles = [
		t('Modellnavn'),
		t('Type'),
		t('Status'),
		t('Innstillinger'),
		'',
	];
	const superAdminTitles = [
		t('Modellnavn'),
		t('Type'),
		t('Kontor'),
		t('Status'),
		t('Innstillinger'),
		'',
	];

	useEffect(() => {
		if (role === 'SuperAdmin') {
			dispatch(getAllPrinters());
			dispatch(getLocations());
		} else if (role === 'Admin') {
			dispatch(getAllPrintersOnLocation());
		}
	}, [dispatch, role]);

	const handleDeletePrinter = async (id: number) => {
		try {
			await confirm({
				title: t('Er du sikker?'),
				description: t(
					`Printeren og tilknyttede data vil bli slettet for godt.`
				),
				confirmationText: t('Slett printer'),
				confirmationButtonProps: {
					color: 'secondary',
					variant: 'contained',
					style: { background: theme.palette.secondary.main },
				},
			});
			dispatch(deletePrinter(id));
		} catch (error) {
			console.log(error);
		}
	};

	const handlePrinterUpdate = async (updatedPrinter: PrinterInput) => {
		try {
			setShowModal(false);
			dispatch(updatePrinter({ id: updatedPrinter.id, printer: updatedPrinter }));

			enqueueSnackbar(t('Printer oppdatert'), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (error) {
			console.log(error);
			enqueueSnackbar(t('Printer ble ikke oppdatert'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	const handleSubmit = async (printer: PrinterInput) => {
		try {
			setShowModal(false);
			await dispatch(createPrinter(printer));
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<StyledDiv>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<AddRoundedIcon />}
					onClick={() => setShowModal(true)}
				>
					{t('Ny printer')}
				</Button>
				{role === 'SuperAdmin' && (
					<SelectLocation
						locations={locations}
						placeholder="Kontor"
						value={locationFilter}
						handleChange={setLocationFilter}
						includeAllLocations
					/>
				)}
			</StyledDiv>
			<AdminTable
				titles={role === 'SuperAdmin' ? superAdminTitles : titles}
				rows={printers
					.filter(
						(printer) =>
							locationFilter === -1 || printer.locationId === locationFilter
					)
					.map((printer) => (
						<PrinterRow
							key={printer.id}
							printer={printer}
							deletePrinter={handleDeletePrinter}
							deleteColor={theme.palette.error.main}
							role={role}
							selectedPrinter={printer}
							printerState={printer.printerState}
							handlePrinterUpdate={handlePrinterUpdate} />
					))}
			/>

			<StyledDiv>
				<Typography variant="body1">
					{t('Printere som har en kø kan ikke slettes, men kan deaktiveres.')}
				</Typography>
			</StyledDiv>
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<StyledPaper>
					<TypographyIcon marginx="large" variant="h5" justifyContent="center">
						<PrintIcon /> {t('Ny printer')}
					</TypographyIcon>
					<PrinterForm
						locations={locations}
						locationId={locationId}
						handleSubmit={handleSubmit}
						role={role}
						printerState={''}
					/>
				</StyledPaper>
			</Modal>

			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<StyledPaper>
					<TypographyIcon marginx="large" variant="h5" justifyContent="center">
						<PrintIcon /> {t('Ny printer')}
					</TypographyIcon>
					<PrinterForm
						locations={locations}
						locationId={locationId}
						handleSubmit={handleSubmit}
						role={role}
						printerState={''}
					/>
				</StyledPaper>
			</Modal>
		</>
	);
};

export const StyledDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 45px;
	justify-content: center;
	align-items: center;
`;

export const StyledPaper = styled(Paper)`
	padding: 1rem;
	position: absolute;
	width: 400px;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	place-items: center;
	flex-direction: column;
`;

export default PrintersTable;

