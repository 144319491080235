import { Printer, PrinterInput } from './../redux/Printer/printeSlice.model';
import axios from './api.config';

export namespace PrinterApi {
	export const getAllPrinters = async (): Promise<Printer[]> => {
		const response = await axios.get<Printer[]>(`Printers/get_all_printers`);
		return response.data;
	};

	export const getAllPrintersOnLocation = async (): Promise<Printer[]> => {
		const response = await axios.get<Printer[]>(
			`Printers/get_all_printers_on_location`
		);
		return response.data;
	};

	export const deletePrinter = async (printerId: number) => {
		await axios.delete(`Printers/delete_printer`, {
			data: {
				printerId,
			},
		});
	};

	export const createPrinter = async (printer: PrinterInput) => {
		const response = await axios.post(`Printers/new_printer`, printer);
		return response.data;
	};


	export const updatePrinter = async (id: number, printer: PrinterInput) => {
  		const response = await axios.put(`Printers/update_printer_${id}`, printer);
  		return response.data;
};
 
}
