import React, { } from 'react';
import { CircularProgress } from '@mui/material';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	enqueuedUsersStatusSel,
	firstUserInQueueSel,
	refreshPrinterAndQueue,
	//removeFromQ,
	unenqueueCurrentUserStatusSel,
	unenqueueUser,
} from '../../../redux/Queue/queueSlice';
import { userIdSel } from '../../../redux/User/userSlice';
import { useSnackbar } from 'notistack';
import { ErrorButton } from '../../../general-components/Button';
import { QueueState } from '../../../models/Queue.models';
import { useTranslation } from 'react-i18next';


export interface UnenqueueUserButtonProps {

}

const UnenqueueUserButton: React.FC<UnenqueueUserButtonProps> = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(userIdSel);
	const { enqueueSnackbar } = useSnackbar();
	const unenqueueCurrentUserStatus = useAppSelector(
		unenqueueCurrentUserStatusSel
	);
	const enqueuedUsersStatus = useAppSelector(enqueuedUsersStatusSel);
	const firstUserInQueue = useAppSelector(firstUserInQueueSel);
	const { t } = useTranslation();

	const handleUnenqueueClick = async () => {
		try {
			if (!userId) {
				throw new Error();
			}

			await dispatch(unenqueueUser(userId)).unwrap();
			dispatch(refreshPrinterAndQueue());

			enqueueSnackbar(t('Du er fjernet fra køen!'), {
				variant: 'info',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (e) {
			enqueueSnackbar(t('Noe gikk galt! Prøv på nytt.'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};



	if (
		userId === firstUserInQueue?.userId &&
		(firstUserInQueue.queueState === QueueState.Printing ||
			firstUserInQueue.queueState === QueueState.Finished)
	)
		return <></>;

	return (
		<ErrorButton
			onClick={handleUnenqueueClick}
			variant="contained"
			color="primary"
			disabled={
				unenqueueCurrentUserStatus === 'loading' ||
				enqueuedUsersStatus === 'loading'
			}
			endIcon={
				unenqueueCurrentUserStatus === 'loading' ? (
					<CircularProgress size={20} />
				) : (
					<RemoveFromQueueIcon />
				)
			}
		>
			{t('Fjern meg fra kø')}
		</ErrorButton>
	);
};
export default UnenqueueUserButton;
