import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrintStatsResponse } from '../../../redux/Stats/stats.models';
import { newStatsApi } from '../../../api/newStats.api';
import SortableTable from './SortableTable';

const StatisticsTable = () => {
	const { t } = useTranslation();
	const [statsList, setStatsList] = useState<PrintStatsResponse[]>([]);

	const titles = [
		t('Kunde'),
		t('Prosjekt'),
		t('Bruker'),
		t('Gram'),
		t('Dato'),
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await newStatsApi.fetchCustomerPrintHistory();
				setStatsList(data);
			} catch (error) {
				console.error('Could not fetch data: ', error);
			}
		};
		fetchData();
	}, []);

	return (
		<>
			<SortableTable
				titles={titles}
				rows={statsList}
			/>
		</>
	);
};
export default StatisticsTable;
