import React, { useState } from 'react';
import {
	Button,
	CircularProgress,
	ClickAwayListener,
	darken,
	InputAdornment,
	TextField,
	Tooltip,
	useTheme,
} from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { Typography } from '@mui/material';
import { faCheck, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	locationNameSel,
	loginUserChain,
	registerUser,
	registerUserStatusSel,
	sendVerificationCode,
	sendVerificationCodeStatusSel,
} from '../../../redux/User/userSlice';
import { UserObj } from '../../../models/Queue.models';
import { Link, useNavigate } from 'react-router-dom';
import {
	FormWrapper,
	StyledForm,
	StyledTextField,
} from '../../../styledcomponents/SimpleForms/SimpleForm';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';
import { FormErrorMessage } from '../../../general-components/Form';
import { StringUtils } from '../../../utils/string.utils';
import { onTheLabSel } from '../../../redux/Location/locationSlice';
import { TypographyIcon } from '../../../styledcomponents/Typography/Typography';
import { Help } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface RegisterUserFormProps {}

type FormInputs = {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	verificationCode: string;
	pinCode: string;
	confirmPinCode: string;
};

const RegisterUserForm: React.FC<RegisterUserFormProps> = () => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const navigate = useNavigate();
	const onTheLab = useAppSelector(onTheLabSel);
	const { enqueueSnackbar } = useSnackbar();
	const registerUserStatus = useAppSelector(registerUserStatusSel);
	const sendVerificationCodeStatus = useAppSelector(
		sendVerificationCodeStatusSel
	);
	const locationName = useAppSelector(locationNameSel);
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	const [verificationStatusMessage, setVerificationStatusMessage] =
		useState<string>('');

	const {
		register,
		handleSubmit,
		setError,
		watch,
		getValues,
		formState: { errors },
	} = useForm<FormInputs>();

	const watchPhoneNumber = watch('phoneNumber');

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		if (data.pinCode !== data.confirmPinCode) {
			setError('confirmPinCode', {
				type: 'manual',
				message: t('PIN-kodene er ikke like. Prøv på nytt'),
			});
			return;
		}

		const user: UserObj = {
			firstName: data.firstName.trim(),
			lastName: data.lastName.trim(),
			phoneNumber: StringUtils.addPhoneCode(data.phoneNumber.trim(), 'no'),
			verificationCode: data.verificationCode.trim(),
			pinCode: data.pinCode.trim(),
		};

		try {
			const resultAction = await dispatch(registerUser(user));
			unwrapResult(resultAction);
			await dispatch(loginUserChain(user));
			navigate(`/${onTheLab ? 'lab' : ''}`);
			enqueueSnackbar(
				`${user.firstName} ${user.lastName}` + t('er nå registrert!'),
				{
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
		} catch (err) {
			enqueueSnackbar(t(`Registrering feilet. Prøv på nytt`), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	const handleSendVerificationCode = async () => {
		const currPhoneNumber = StringUtils.addPhoneCode(
			getValues('phoneNumber').trim(),
			'no'
		);
		if (currPhoneNumber === undefined) {
			setError('phoneNumber', {
				type: 'manual',
				message: t('Skriv inn et gyldig telefonnummer for å verifisere.'),
			});
			return;
		}

		try {
			await dispatch(sendVerificationCode(currPhoneNumber)).unwrap();
			const feedbackMessage =
				t('En verifiseringskode har blitt sendt til') + `${currPhoneNumber}.`;
			setVerificationStatusMessage(feedbackMessage);
			enqueueSnackbar(feedbackMessage, {
				variant: 'info',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (e) {
			enqueueSnackbar(
				t(
					`Kunne ikke sende verifiseringskode til ${currPhoneNumber}. Prøv på nytt`
				),
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
		}
	};

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<Typography variant="subtitle2">
				{t('Har du allerede en bruker?')}{' '}
				<Link to="/login">{t('Logg inn')}</Link>
			</Typography>
			<br />

			<TypographyIcon variant="subtitle1" justifyContent="flex-start">
				{t('Kontor:')}
				<b
					style={{
						color: darken(theme.palette.primary.dark, 0),
						marginLeft: '0.3rem',
					}}
				>
					{locationName}
				</b>{' '}
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<Tooltip
						onClose={handleTooltipClose}
						open={open}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						title={t(`Vi finner ditt kontor ved å se på hvilken IP-addresse du bruker. 
						NB: ikke bruk VPN når du registrerer ny bruker, da vil du kunne få feil lokasjon.`)}
					>
						<Help
							fontSize="small"
							style={{ marginLeft: '.2rem' }}
							onClick={handleTooltipOpen}
						/>
					</Tooltip>
				</ClickAwayListener>
			</TypographyIcon>

			<StyledForm noValidate autoComplete="off">
				<Typography variant="subtitle1">
					<b>{t('Personinformasjon')}</b>
				</Typography>
				<StyledTextField
					id="firstName"
					{...register('firstName', {
						required: t('Fornavn er påkrevd. Skriv inn fornavn.'),
						maxLength: 50,
						minLength: 2,
					})}
					color="secondary"
					label={t('Fornavn')}
					placeholder="Ola"
					variant="outlined"
					inputProps={{ maxLength: 20 }}
				/>
				{errors.firstName && <FormErrorMessage error={errors.firstName} />}

				<StyledTextField
					id="lastName"
					{...register('lastName', {
						required: t('Etternavn er påkrevd. Skriv inn etternavn.'),
						maxLength: 50,
						minLength: 2,
					})}
					color="secondary"
					label={t('Etternavn')}
					placeholder="Nordmann"
					variant="outlined"
				/>
				{errors.lastName && <FormErrorMessage error={errors.lastName} />}

				<StyledTextField
					id="phoneNumber"
					{...register('phoneNumber', {
						required: t(
							'Telefonnummer er påkrevd. Skriv inn telefonnummeret ditt.'
						),
						maxLength: 8,
						minLength: 8,
					})}
					color="secondary"
					label={t('Telefonnnummer')}
					placeholder="987 65 432"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">+47</InputAdornment>
						),
					}}
				/>
				{errors.phoneNumber && <FormErrorMessage error={errors.phoneNumber} />}

				<VerifiactionWrapper>
					<Button
						onClick={handleSendVerificationCode}
						variant="contained"
						color="primary"
						endIcon={
							sendVerificationCodeStatus === 'loading' ? (
								<CircularProgress size={20} />
							) : (
								<FontAwesomeIcon icon={faKey} />
							)
						}
						disabled={
							!watchPhoneNumber || sendVerificationCodeStatus === 'loading'
						}
					>
						{t('Send kode')}
					</Button>

					<VerificationTextField
						id="verificationCode"
						{...register('verificationCode', {
							required: t(
								'Verifiseringskode er påkrevd. Skriv inn verifiseringskode som du har blitt tilsendt på sms.'
							),
							maxLength: 6,
							minLength: 6,
						})}
						color="secondary"
						label={t('Verifiseringskode')}
						placeholder="321654"
						variant="outlined"
					/>
				</VerifiactionWrapper>
				{errors.verificationCode && (
					<FormErrorMessage error={errors.verificationCode} />
				)}

				{verificationStatusMessage && (
					<Typography
						variant="body2"
						style={{ color: darken(theme.palette.info.dark, 0.1) }}
					>
						{verificationStatusMessage}
					</Typography>
				)}

				<br />
				<Typography variant="subtitle1">
					<b>{t('PIN-kode')}</b>
				</Typography>
				<Typography variant="body2">
					{t('Velg en PIN-kode som består av 4 tall')}
				</Typography>
				<StyledTextField
					{...register('pinCode', {
						required: t('PIN-kode er påkrevd. Prøv på nytt'),
						maxLength: 4,
						minLength: 4,
					})}
					id="pinCode"
					color="secondary"
					label={t('PIN-kode')}
					type="password"
					variant="outlined"
				/>
				{errors.pinCode && <FormErrorMessage error={errors.pinCode} />}

				<StyledTextField
					{...register('confirmPinCode', {
						required: t('PIN-kode er påkrevd. Prøv på nytt'),
						maxLength: 4,
						minLength: 4,
					})}
					id="confirmPinCode"
					color="secondary"
					label={t('Gjenta PIN-kode')}
					type="password"
					variant="outlined"
				/>
				{errors.confirmPinCode && (
					<FormErrorMessage error={errors.confirmPinCode} />
				)}

				<br />
				<Button
					endIcon={
						registerUserStatus === 'loading' ? (
							<CircularProgress size={20} />
						) : (
							<FontAwesomeIcon icon={faCheck} />
						)
					}
					type="submit"
					variant="contained"
					color="secondary"
					id="submit"
					disabled={registerUserStatus === 'loading'}
				>
					{t('Registrer')}
				</Button>
			</StyledForm>
			<br />
		</FormWrapper>
	);
};

const VerifiactionWrapper = withTheme(styled.div`
	margin-top: 1rem;
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 0.5rem;
`);

const VerificationTextField = withTheme(styled(TextField)`
	& .MuiInputBase-root {
		height: 100%;
	}
`);

export default RegisterUserForm;
