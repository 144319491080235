import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import queueReducer from './Queue/queueSlice';
import userReducer from './User/userSlice';
import printerReducer from './Printer/printerSlice';
import locationReducer from './Location/locationSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { statsApi } from '../api/stats.api';
import { useDispatch } from 'react-redux';

const userConfig = {
	key: 'user',
	storage: storage,
	whitelist: [
		'jwtToken',
		'userId',
		'role',
		'phoneNumber',
		'firstName',
		'lastName',
		'locationId',
	],
};

const printerConfig = {
	key: 'printer',
	storage: storageSession,
	whitelist: ['activePrinterId'],
};

const locationConfig = {
	key: 'location',
	storage: storage,
	whitelist: ['onTheLab'],
};

const rootReducer = combineReducers({
	counter: counterReducer,
	queue: queueReducer,
	user: persistReducer(userConfig, userReducer),
	printer: persistReducer(printerConfig, printerReducer),
	location: persistReducer(locationConfig, locationReducer),
	[statsApi.reducerPath]: statsApi.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(statsApi.middleware),
});

export const persistor = persistStore(store);
export const useStoreDispatch = () => useDispatch<AppDispatch>();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

export interface ThunkAPI {
	dispatch: Function;
	getState: Function;
	extra?: any;
	requestId: string;
	signal: AbortSignal;
}
