import React, { memo, useEffect } from 'react';
import { darken, Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';
import { TimeUtils } from '../../../utils/time.utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	decrementRemainingTimeInStateSec,
	remainingTimeInStateSecSel,
	setRemainingTimeInStateSec,
} from '../../../redux/Queue/queueSlice';

export interface EndTimeCountdownProps {
	remainingTimeInStateSec: number;
}

const RemainingTimeInStateCountdown: React.FC<EndTimeCountdownProps> = ({
	remainingTimeInStateSec,
}) => {
	const dispatch = useAppDispatch();
	const remainingTimeInStateSecRedux = useAppSelector(
		remainingTimeInStateSecSel
	);

	useEffect(() => {
		dispatch(setRemainingTimeInStateSec(remainingTimeInStateSec));

		const interval = setInterval(() => {
			dispatch(decrementRemainingTimeInStateSec(60));
		}, TimeUtils.oneMinuteupdateIntervalMS);
		return () => {
			clearInterval(interval);
		};
	}, [dispatch, remainingTimeInStateSecRedux, remainingTimeInStateSec]);

	return (
		<StyledTypography variant="h6">
			{TimeUtils.toHHMMSS(remainingTimeInStateSecRedux).HtMmDisplayString}
		</StyledTypography>
	);
};

const StyledTypography = withTheme(styled(Typography)`
	margin-left: 0.8rem;
	font-weight: 600;
	text-shadow: ${(props) =>
		`1px 1px 6px ${darken(props.theme.palette.secondary.dark, 0.7)}`};
`);

export default memo(RemainingTimeInStateCountdown);
