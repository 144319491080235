export interface QueueItemObj {
	queueItemId: number;
	id: number;
	queueState: QueueState;
	position: number;
	startTime?: Date | null;
	endTime?: Date | null;
	printerId: number;
	printDurationSec: number;
	numberOfRestarts: number;
	remainingTimeInStateSec: number;
	firstInQueueTime: Date | null;
	postponesLeft: number;
	numberOfPreparationAdds: number;
	customer: string;
	project: string;
}

export interface UserObj {
	userId?: number;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
	verificationCode?: string;
	pinCode?: string;
}

export interface UserVipObj {
	userId: number;
	customer: string;
	project: string;
}

export interface EnqueuedUser extends QueueItemObj, UserObj {}

export enum QueueState {
	InQueue = 'InQueue',
	ReadyToPrint = 'ReadyToPrint',
	Printing = 'Printing',
	Finished = 'Finished',
	Completed = 'Completed',
}

export type QueueStateString =
	| 'InQueue'
	| 'ReadyToPrint'
	| 'Printing'
	| 'Finished'
	| 'Completed';

export const isHighlightQueueState = (queueState: QueueStateString): string => {
	if (
		queueState === 'ReadyToPrint' ||
		queueState === 'Printing' ||
		queueState === 'Finished'
	) {
		return 'true';
	}
	return 'false';
};

export interface CompletedEnqueuedUser extends EnqueuedUser {
	completedEnqueuedUserId: number;
	originalEndTime: Date;
	finishedTime: Date;
	queueCountWhenFinished: number;
	extendPrintTimeCount: number;
	printSuccessful: boolean;
	numberOfPreparationAdds:number;
}

export interface CompletedEnqueuedUserResponse {
	id: number;
	userId: number;
	queueItemId: number;
	startTime: string;
	endTime: string;
	originalEndTime: string;
	firstInQueueTime: string;
	finishedTime: string;
	postponesLeft: number;
	queueCountWhenFinished: number;
	extendPrintTimeCount: number;
	printSuccessful: true;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	printDurationSec: number;
	numberOfRestarts: number;
	numberOfPreparationAdds:number;
}

export interface GetCompletedEnqueuedUsersRequest {
	printerId: number;
	numberOfUsers: number;
}
