import { ApiStatus } from './../models';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Printer, PrinterInput } from './printeSlice.model';
import { PrinterApi } from '../../api/printer.api';

export interface IPrinterState {
	printers: Printer[];
	activePrinterId: number | null;
	getAllPrintersStatus: ApiStatus;
	getAllPrintersOnLocationStatus: ApiStatus;
	deletePrinterStatus: ApiStatus;
	createPrinterStatus: ApiStatus;
	updatePrinterStatus: ApiStatus;	
	
}

const initialState: IPrinterState = {
	printers: [],
	activePrinterId: null,
	getAllPrintersStatus: 'idle',
	getAllPrintersOnLocationStatus: 'idle',
	deletePrinterStatus: 'idle',
	createPrinterStatus: 'idle',
	updatePrinterStatus: 'idle',
	
};

export const getAllPrinters = createAsyncThunk(
	'printer/getAllPrinters',
	async (): Promise<Printer[]> => {
		const data: Printer[] = await PrinterApi.getAllPrinters();
		return data;
	}
);

export const getAllPrintersOnLocation = createAsyncThunk(
	'printer/getAllPrintersOnLocation',
	async (): Promise<Printer[]> => {
		const data: Printer[] = await PrinterApi.getAllPrintersOnLocation();
		return data;
	}
);

export const createPrinter = createAsyncThunk(
	'printer/createPrinter',
	async (printer: PrinterInput): Promise<Printer> => {
		const data: Printer = await PrinterApi.createPrinter(printer);
		return data;
	}
);

export const updatePrinter = createAsyncThunk(
	'printer/updatePrinter',
	async ({ id, printer }: { id: number, printer: PrinterInput }): Promise<Printer> => {
	  const data: Printer = await PrinterApi.updatePrinter(id, printer);
	  return data;
	}

  );
  
export const deletePrinter = createAsyncThunk(
	'printer/deletePrinter',
	async (id: number): Promise<number> => {
		await PrinterApi.deletePrinter(id);
		return id;
	}
);

export const printerSlice = createSlice({
	name: 'printer',
	initialState,
	reducers: {
		setActivePrinterId: (state, action: PayloadAction<number>) => {
			state.activePrinterId = action.payload || state.activePrinterId;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllPrinters.pending, (state) => {
				state.getAllPrintersStatus = 'loading';
			})
			.addCase(getAllPrinters.fulfilled, (state, action) => {
				state.getAllPrintersStatus = 'idle';
				state.printers = action.payload;
			})
			.addCase(getAllPrinters.rejected, (state, action) => {
				state.getAllPrintersStatus = 'failed';
			})
			.addCase(getAllPrintersOnLocation.pending, (state) => {
				state.getAllPrintersOnLocationStatus = 'loading';
			})
			.addCase(getAllPrintersOnLocation.fulfilled, (state, action) => {
				state.getAllPrintersOnLocationStatus = 'idle';
				state.printers = action.payload;
			})
			.addCase(getAllPrintersOnLocation.rejected, (state, action) => {
				state.getAllPrintersOnLocationStatus = 'failed';
			})
			.addCase(deletePrinter.pending, (state) => {
				state.deletePrinterStatus = 'loading';
			})
			.addCase(deletePrinter.fulfilled, (state, action) => {
				state.deletePrinterStatus = 'idle';
				state.printers = state.printers.filter(
					(printer) => printer.id !== action.payload
				);
			})
			.addCase(deletePrinter.rejected, (state, action) => {
				state.deletePrinterStatus = 'failed';
			})
			
			.addCase(createPrinter.pending, (state) => {
				state.createPrinterStatus = 'loading';
			})
			.addCase(createPrinter.fulfilled, (state, action) => {
				state.createPrinterStatus = 'idle';
				state.printers = [
					...state.printers.filter(
						(printer) => printer.id !== action.payload.id
					),
					action.payload,
				];
			})
			
			  
			.addCase(createPrinter.rejected, (state, action) => {
				state.createPrinterStatus = 'failed';
			})
			
			.addCase(updatePrinter.pending, (state) => {
				state.updatePrinterStatus = 'loading';
			})

			.addCase(updatePrinter.fulfilled, (state, action) => {
				state.updatePrinterStatus = 'idle';
				state.printers = [
					...state.printers.filter(
						(printer) => printer.id !== action.payload.id
					),
					action.payload,
				];
			})
			.addCase(updatePrinter.rejected, (state, action) => {
				state.updatePrinterStatus = 'failed';
			});



			
	},
});

export const { setActivePrinterId } = printerSlice.actions;

export const printersSel = (state: RootState) => state.printer.printers;
export const activePrinterIdSel = (state: RootState) =>
	state.printer.activePrinterId;
export const getPrintersStatusSel = (state: RootState) =>
	state.printer.getAllPrintersStatus;
export const deletePrinterStatusSel = (state: RootState) =>
	state.printer.deletePrinterStatus;
export const createPrinterStatusSel = (state: RootState) =>
	state.printer.createPrinterStatus;
export default printerSlice.reducer;
