import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './general-components/Navbar';
import HomePage from './pages/HomePage/HomePage';
import Footer from './general-components/Footer';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import LoginPage from './pages/LoginPage/LoginPage';
import FaqPage from './pages/FaqPage/FaqPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import AdminPage from './pages/AdminPage/AdminPage';
import { useAppSelector } from './redux/hooks';
import { acceptedLocationSelector, userIdSel } from './redux/User/userSlice';
import PasswordReset from './pages/ResetPasswordPage/ResetPasswordPage';
import DashboardParent from './pages/DashboardPage/DashboardPage';
import GetStartedPage from './pages/GetStartedPage/GetStartedPage';

const MainRouter = () => {
	return (
		<Router>
			<Navbar />

			<MainContentWrapper>
				<Routes>
					<Route path='register' element={<PrivateLabRoute children={<RegisterPage />} />} />

					<Route path="/profile" element={<PrivateAuthRoute children={<ProfilePage />} />} /> 

					<Route path="/dashboard" element={<PrivateAuthRoute children={<DashboardParent isAdmin={false}/>} />} />

					<Route path="/login" element={<LoginPage />} />

					<Route path="/resetpassword" element={<PasswordReset />} />

					<Route path="/faq" element={<FaqPage />} />

					<Route path="/get-started" element={<GetStartedPage />} />

					<Route path="/admin" element={<PrivateAuthRoute children={<AdminPage />} />} /> 

					<Route path="/lab" element={<PrivateLabRoute children={<HomePage lab={true} />} />} />

					<Route path="/" element={<HomePage lab={false} />} />
				</Routes>
			</MainContentWrapper>

			<Footer />
		</Router>
	);
};

const MainContentWrapper = styled.div`
	min-height: 100%;
	padding-top: 4rem;
	padding-bottom: 10rem;
`;

interface PrivateRouteProps {
	children: any;
}

const PrivateAuthRoute = ({ children }: PrivateRouteProps) => {
	const userId = useAppSelector(userIdSel);

	return (
			userId && userId > 0 ? children : <Navigate to="/login" />
	);
};

const PrivateLabRoute = ({ children }: PrivateRouteProps) => {
	const acceptedLocation = useAppSelector(acceptedLocationSelector);

	return (
		acceptedLocation ? children : <Navigate to="/" />
	);
};

export default MainRouter;
