/* import { Printer, PrinterInput } from './../redux/Printer/printeSlice.model'; */
import {
	Location,
	LocationRequestBody,
} from '../redux/Location/locationSlice.model';
import { ObjectUtils } from '../utils/object.utils';
import axios from './api.config';

export namespace LocationApi {
	export const getLocations = async (): Promise<Location[]> => {
		const response = await axios.get<Location[]>('Locations/get_all_locations');
		return response.data;
	};

	export const deleteLocation = async (locationId: number): Promise<any> => {
		return await axios.delete<void>(`Locations/delete_location`, {
			data: {
				locationId,
			},
		});
	};

	export const createLocation = async (
		name: string,
		ipAddress: string
	): Promise<Location> => {
		try {
			const response = await axios.post('Locations/new_location', {
				name,
				ipAddress,
			});
			return response.data;
		} catch (error: any) {
			throw new Error(error.response.data.message);
		}
	};

	export const getMyLocation = async (): Promise<Location> => {
		const response = await axios.get<Location>('Locations/get_my_location');
		return response.data;
	};

	export const updateLocation = async (
		locationId: number,
		location: LocationRequestBody
	): Promise<Location> => {
		const response = await axios.put(
			`Locations/update_location_${locationId}`,
			{
				...ObjectUtils.removeNullOrUndefinedValues(location),
			}
		);
		return response.data;
	};
}
