import { useTheme } from '@mui/material';
import React from 'react';
import { TypographyIcon } from '../styledcomponents/Typography/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

export interface PrintSuccessfulTagProps {
	printSuccessful: boolean;
	typographyVariant: 'h4' | 'h6';
	iconOnly?: boolean;
}

const PrintSuccessfulTag: React.FC<PrintSuccessfulTagProps> = ({
	printSuccessful,
	typographyVariant,
	iconOnly,
}) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<>
			{printSuccessful ? (
				<TypographyIcon
					variant={typographyVariant}
					fontcolor={theme.palette.success.main}
				>
					<CheckCircle />
					{!iconOnly && t('Vellykket')}
				</TypographyIcon>
			) : (
				<TypographyIcon
					variant={typographyVariant}
					fontcolor={theme.palette.error.main}
				>
					<CancelIcon />
					{!iconOnly && t('Mislykket')}
				</TypographyIcon>
			)}
		</>
	);
};

export default PrintSuccessfulTag;
