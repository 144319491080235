import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PrinterInput } from '../../../redux/Printer/printeSlice.model';
import { Role } from '../../../redux/User/userSlice.models';
import { Location } from '../../../redux/Location/locationSlice.model';
import SelectLocation from './SelectLocation';
import { useTranslation } from 'react-i18next';

interface PrinterFormProps {
	locationId: number | undefined | null;
	handleSubmit: (printer: PrinterInput) => void;
	role: Role | undefined;
	locations: Location[];
	printerState: string;
}
const PrinterForm = ({
	locationId,
	handleSubmit,
	role,
	locations,

}: PrinterFormProps) => {
	const [modelName, setModelName] = useState<string>('');
	const [printerSpesifics, setPrinterSpecifics] = useState<string>('');
	const [printerState,] = useState<'Available' | 'Disabled'>('Available');
	const [location, setLocation] = useState<number | undefined | null>(
		locationId
	);
	const { t } = useTranslation();

	return (
		<StyledForm
			onSubmit={(e: React.SyntheticEvent) => {
				e.preventDefault();
				handleSubmit({
					modelName,
					printerSpesifics,
					locationId: location ?? 0,
					printerState: printerState,
					id: 0
				});
			}}
		>
			<StyledTextField
				label={t('Modellnavn')}
				value={modelName}
				onChange={(event) => setModelName(event.target.value)}
			/>
			<StyledTextField
				label={t('Type')}
				value={printerSpesifics}
				onChange={(event) => setPrinterSpecifics(event.target.value)}
			/>
			{locationId && role === 'SuperAdmin' && (
				<SelectLocation
					includeAllLocations={false}
					locations={locations}
					value={location}
					handleChange={(value: number) => setLocation(value)}
				/>
			)}
			{locationId && role === 'Admin' && (
				<StyledTextField label={t('Kontor')} disabled value={locationId} />
			)}
			<StyledButton type="submit" variant="contained" color="secondary">
				{t('Legg til')}
			</StyledButton>
		</StyledForm>
	);
};

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	
`;

export const StyledTextField = styled(TextField)`
	margin: 10px 0;
`;

export const StyledButton = styled(Button)`
	margin-top: 20px;
	width: 400px;
`;


export default PrinterForm;
