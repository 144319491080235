import { AccordionDetails, Box, Typography } from '@mui/material';
import React from 'react';
import { TimeUtils } from '../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { PrintStats } from '../../../redux/Stats/stats.models';

interface Props {
	print: PrintStats;
}

const PrintDetails: React.FC<Props> = ({ print }: Props) => {
	const { t } = useTranslation();
	return (
		<AccordionDetails
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				margin: '-1rem 1rem 1rem 1rem',
				padding: '2rem 2rem 2rem 1rem',
				borderRadius: '6px',
				boxShadow: theme.shadows[3],
			}}
		>
			<Box>
				<Typography>
					{t('Skriver')} id: {print.printerId}
				</Typography>
				<Typography>
					{t('Køvare')} id: {print.queueItemId}
				</Typography>
				<Typography>
					{t('Utsetter igjen')}: {print.postponesLeft}
				</Typography>
				<Typography>
					{t('Køtelling når ferdig')}: {print.queueCountWhenFinished}
				</Typography>
				<Typography>
					{t('Utvidet utskriftstid')}: {print.extendPrintTimeCount}
				</Typography>
				{print.gramFilament && (
					<Typography>
						{t('Filamentbruk')}: {print.gramFilament}g
					</Typography>
				)}
			</Box>
			<Box>
				{print.startTime && (
					<Typography>
						{t('Starttid')}: <br className="mobile-break" />
						{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(print.startTime)}
					</Typography>
				)}
				{print.endTime && (
					<Typography>
						{t('Sluttid')}: <br className="mobile-break" />
						{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(print.endTime)}
					</Typography>
				)}
				{print.originalEndTime && (
					<Typography>
						{t('Opprinnelig sluttid')}: <br className="mobile-break" />
						{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(print.originalEndTime)}
					</Typography>
				)}
				{print.firstInQueueTime && (
					<Typography>
						{t('Først i kø tid')}: <br className="mobile-break" />
						{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(print.firstInQueueTime)}
					</Typography>
				)}
				{print.finishedTime && (
					<Typography>
						{t('Ferdig tid')}: <br className="mobile-break" />
						{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(print.finishedTime)}
					</Typography>
				)}
				{print.enqueuedTime && (
					<Typography>
						{t('Tid i kø')}: <br className="mobile-break" />
						{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(print.enqueuedTime)}
					</Typography>
				)}
			</Box>
		</AccordionDetails>
	);
};

export default PrintDetails;
