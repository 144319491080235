import { useState } from 'react';
import AdminTable from './AdminTable';
import BusinessIcon from '@mui/icons-material/Business';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Paper } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useTheme, Modal } from '@mui/material';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	createLocation,
	deleteLocation,
	locationsSel,
} from '../../../redux/Location/locationSlice';
import { TypographyIcon } from '../../../styledcomponents/Typography/Typography';
import LocationForm from './LocationForm';
import LocationRow from './rows/LocationRow';
import { LocationBasic } from '../../../redux/Location/locationSlice.model';
import { SnackbarProvider, useSnackbar, OptionsObject } from 'notistack';
import { useTranslation } from 'react-i18next';

export const successSnack: OptionsObject = {
	variant: 'success',
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
};
const LocationsTable = () => {
	const confirm = useConfirm();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const locations = useAppSelector(locationsSel);
	const [showModal, setShowModal] = useState<boolean>(false);
	const { t } = useTranslation();

	const titles = [t('Kontor'), t('IP-adresse'), ''];

	const removeLocation = async (id: number) => {
		try {
			await confirm({
				title: t('Er du sikker?'),
				description: t(`Kontoret og tilknyttede data vil bli slettet for godt.`),
				confirmationText: t('Slett printer'),
				confirmationButtonProps: {
					color: 'secondary',
					variant: 'contained',
					style: { background: theme.palette.secondary.main },
				},
				cancellationText: t('Avbryt'),
			});
			await dispatch(deleteLocation({ id }));
			enqueueSnackbar(t('Kontor slettet'), successSnack);
		} catch (error) {
			console.log(error);
		}
	};

	const createNewLocation = async ({ name, ipAddress }: LocationBasic) => {
		try {
			setShowModal(false);
			await dispatch(createLocation({ name, ipAddress })).unwrap();
			enqueueSnackbar(t('Kontor opprettet'), successSnack);
		} catch (error: any) {
			enqueueSnackbar(error.message, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	return (
		<SnackbarProvider>
			<StyledDiv>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<AddRoundedIcon />}
					onClick={() => setShowModal(true)}
				>
					{t('Nytt kontor')}
				</Button>
			</StyledDiv>
			<AdminTable
				titles={titles}
				rows={locations.map((location) => (
					<LocationRow
						key={location.id}
						location={location}
						deleteLocation={removeLocation}
						deleteColor={theme.palette.error.main}
					/>
				))}
			/>
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<StyledPaper>
					<TypographyIcon
						marginx="large"
						variant="h5"
					>
						<BusinessIcon /> {t('Nytt kontor')}
					</TypographyIcon>
					<LocationForm handleSubmit={createNewLocation} />
				</StyledPaper>
			</Modal>
		</SnackbarProvider>
	);
};

const StyledDiv = styled.div`
	width: 100%;
	display: flex;
	margin-top: 45px;
	justify-content: center;
`;

const StyledPaper = styled(Paper)`
	padding: 1rem;
	position: absolute;
	width: 400px;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	place-items: center;
	flex-direction: column;
`;

export default LocationsTable;
