import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	CircularProgress,
	InputAdornment,
	Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { FormErrorMessage } from '../../../general-components/Form';
import { UserObj } from '../../../models/Queue.models';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { onTheLabSel } from '../../../redux/Location/locationSlice';
import {
	authenticateUserStatusSel,
	loginUserChain,
} from '../../../redux/User/userSlice';
import {
	FormWrapper,
	StyledForm,
	StyledTextField,
} from '../../../styledcomponents/SimpleForms/SimpleForm';
import { StringUtils } from '../../../utils/string.utils';
import { useTranslation } from 'react-i18next';

export interface LoginFormProps {}

type FormInputs = {
	phoneNumber: string;
	pinCode: string;
};

const LoginForm: React.FC<LoginFormProps> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const authenticateUserStatus = useAppSelector(authenticateUserStatusSel);
	const { enqueueSnackbar } = useSnackbar();
	const onTheLab = useAppSelector(onTheLabSel);
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<FormInputs>();

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		const user: UserObj = {
			phoneNumber: StringUtils.addPhoneCode(data.phoneNumber.trim(), 'no'),
			pinCode: data.pinCode.trim(),
		};

		try {
			await dispatch(loginUserChain(user));

			// successfull authentication
			navigate(`/${onTheLab ? 'lab' : ''}`);
			enqueueSnackbar(t(`Du er nå innlogget`), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
					
				},
			});
		} catch (err: any) {
			// unsuccessfull authentication
			setError('pinCode', {
				type: 'manual',
				message: t('Kombinasjonen av telefonnummer og PIN-kode er ikke riktig.'),
			});
				enqueueSnackbar(t('Innlogging feilet. Prøv på nytt.'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<StyledForm noValidate autoComplete="off">
				<Typography variant="subtitle2">
					{t('Har du ikke en eksisterende bruker?')}{' '}
					<Link to="/register">{t("Registrer ny bruker")}</Link>
				</Typography>
				<StyledTextField
					id="phoneNumber"
					{...register('phoneNumber', {
						required: t('Telefonnummer er påkrevd. Skriv inn Telefonnummer.'),
						maxLength: 8,
						minLength: 8,
					})}
					color="secondary"
					label={t("Telefonnummer")}
					placeholder="987 65 432"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">+47</InputAdornment>
						),
					}}
				/>
				{errors.phoneNumber && <FormErrorMessage error={errors.phoneNumber} />}

				<StyledTextField
					id="pinCode"
					{...register('pinCode', {
						required:
							t('Kombinasjon av telefonnummer og PIN-kode er ikke riktig.'),
						maxLength: 4,
						minLength: 4,
					})}
					color="secondary"
					label={t("PIN-kode")}
					variant="outlined"
					type="password"
				/>

				{errors.pinCode && <FormErrorMessage error={errors.pinCode} />}

				<br />
				<Button
					endIcon={
						authenticateUserStatus === 'loading' ? (
							<CircularProgress size={20} />
						) : (
							<FontAwesomeIcon icon={faSignInAlt} />
						)
					}
					type="submit"
					variant="contained"
					color="secondary"
					id="submit"
					disabled={authenticateUserStatus === 'loading'}
				>
					{t('Logg inn')}
				</Button>
				<Typography variant="subtitle2" sx={{ mt: 2 }}>
					{t('Har du glemt pinkoden din?')}{' '}
					<Link to="/resetpassword">{t('Få en ny pinkode på mobilen')}</Link>
				</Typography>
			</StyledForm>
		</FormWrapper>
	);
};

export default LoginForm;
