import { Typography, TypographyVariant } from '@mui/material';
import styled from 'styled-components';

// Bold text
export const B = styled(Typography)`
	display: inline;
	font-weight: 700;
`;

interface TypographyIconProps {
	variant: TypographyVariant;
	marginx?: 'small' | 'medium' | 'large';
	noMarginLeft?: boolean;
	fontcolor?: string;
	justifyContent?:
		| 'space-evenly'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'flex-start';
}

export const TypographyIcon = styled(Typography)<TypographyIconProps>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justifyContent || 'space-evenly'};
	color: ${(props) => props.fontcolor && props.fontcolor};
	& > svg {
		margin-right: ${(props: TypographyIconProps) => {
			switch (props.marginx) {
				case 'small':
					return '0.2rem';
				case 'medium':
					return '0.4rem';
				case 'large':
					return '0.7rem';
				default:
					return '0.4rem';
			}
		}};
		font-size: ${(props: TypographyIconProps) => {
			switch (props.variant) {
				case 'h1':
					return '6rem';
				case 'h2':
					return '3.75rem';
				case 'h3':
					return '3rem';
				case 'h4':
					return '2.125rem';
				case 'h5':
					return '1.5rem';
				case 'h6':
					return '1.25rem';
			}
		}};
	}
`;
