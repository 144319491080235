import { useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	roleSel,
	userIdSel,
	getUsers,
	usersSel,
	deleteUser,
} from '../../../redux/User/userSlice';
import { StyledDiv } from './PrintersTable';
import { locationsSel } from '../../../redux/Location/locationSlice';
import AdminTable from './AdminTable';
import SelectLocation from './SelectLocation';
import UserRow from './rows/UserRow';
import { useTranslation } from 'react-i18next';

const UsersTable = () => {
	const [locationFilter, setLocationFilter] = useState<number>(-1);
	const dispatch = useAppDispatch();
	const confirm = useConfirm();
	const theme = useTheme();
	const locations = useAppSelector(locationsSel);
	const role = useAppSelector(roleSel);
	const userId = useAppSelector(userIdSel);
	const users = useAppSelector(usersSel);
	const { t } = useTranslation();

	const titles = [t('Navn'), t('Telefon'), t('Rolle'), t('Gram'), ''];
	const superAdminTitles = [
		t('Navn'),
		t('Telefon'),
		t('Lokasjon'),
		t('Rolle'),
		t('Gram'),
		'',
	];

	const fetchUsers = async () => {
		try {
			await dispatch(getUsers()).unwrap();
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteUser = async (userId: number) => {
		try {
			await confirm({
				title: t('Er du sikker?'),
				description: t('Brukeren og tilknyttede data vil bli slettet for godt.'),
				confirmationText: t('Slett bruker'),
				confirmationButtonProps: {
					color: 'secondary',
					variant: 'contained',
					style: { background: theme.palette.secondary.main },
				},
				cancellationText: t('Avbryt'),
			});
			await dispatch(deleteUser(userId)).unwrap();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{role === 'SuperAdmin' && (
				<StyledDiv>
					<SelectLocation
						locations={locations}
						placeholder={t("Kontor")}
						value={locationFilter}
						handleChange={setLocationFilter}
						includeAllLocations
					/>
				</StyledDiv>
			)}
			<AdminTable
				titles={role === 'SuperAdmin' ? superAdminTitles : titles}
				rows={
					users
						? users
								.filter(
									(user) =>
										locationFilter === -1 || user.locationId === locationFilter
								)
								.map((user) => (
									<UserRow
										key={user.id}
										myUserId={userId}
										role={role}
										user={user}
										deleteUser={handleDeleteUser}
										deleteColor={theme.palette.error.main}
										locations={locations}
									/>
								))
						: []
				}
			/>
			<StyledDiv>
				<Typography variant="body1">
					{t('Du kan ikke endre rolle på din egen bruker.')}
				</Typography>
			</StyledDiv>
		</>
	);
};

export default UsersTable;
