import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NavbarLinks from './NavbarLinks';
import { Button } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export interface SideNavbarProps {
	open: boolean;
	setShowSidenav: Function;
}

const SideNavbar: React.FC<SideNavbarProps> = ({ open, setShowSidenav }) => {
	const handleLinkCallback = () => setShowSidenav(false);
	const { t } = useTranslation();

	return (
		<StyledSwipeableDrawer
			anchor="right"
			open={open}
			onClose={() => setShowSidenav(false)}
			onOpen={() => setShowSidenav(true)}
		>
			<InsideWrapper>
				<div>
					<NavbarLinks onClickCallback={handleLinkCallback} />
				</div>

				<div>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => setShowSidenav(false)}
					>
						{t('Lukk')}
					</Button>
				</div>
			</InsideWrapper>
		</StyledSwipeableDrawer>
	);
};

const StyledSwipeableDrawer = withTheme(styled(SwipeableDrawer)`
	& .MuiDrawer-paper {
		background: ${(props) => props.theme.palette.primary.dark};
		padding: 2rem 1.5rem;
		color: white;
		box-sizing: border-box;
	}
`);

const InsideWrapper = withTheme(styled.div`
	height: 100%;
	width: min-content;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`);

export default SideNavbar;
