import { TableRow, TableCell } from '@mui/material';
import { PrintStatsResponse } from '../../../../redux/Stats/stats.models';
import dayjs from 'dayjs';

interface StatisticsProps {
	stats: PrintStatsResponse;
}

const StatisticsRow = ({ stats }: StatisticsProps) => {
	return (
		<>
			<TableRow key={stats.queueItemId}>
				<TableCell component="th" scope="row">
					{stats.customer}
				</TableCell>
				<TableCell scope="row">{stats.project}</TableCell>
				<TableCell scope="row">
					{stats.firstName + ' ' + stats.lastName}
				</TableCell>
				<TableCell scope="row">{stats.gramFilament + ' gram'}</TableCell>
				<TableCell scope="row">
					{stats.gramFilament + ' gram'}
				</TableCell>
				<TableCell scope="row">
					{stats.endTime ? dayjs(stats.endTime).format('DD/MM/YYYY') : 'N/A'}
				</TableCell>
			</TableRow>
		</>
	);
};
export default StatisticsRow;
