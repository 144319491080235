import React, { useState } from 'react';
import {
	Container,
	Box,
	Modal,
	Button,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import videoGuide from './assets/videoGuide.mp4';
import printer from './assets/printer.png';

export interface GetStartedPageProps {}

const GetStartedPage: React.FC<GetStartedPageProps> = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Container maxWidth="md">
			<FaqPageWrapper>
				<GridItem>
					<Typography color="secondary" variant="h2">
						<FontAwesomeIcon
							icon={faPlay}
							size="1x"
							style={{ marginRight: '1rem' }}
						/>
						{t(`KOM IGANG`)}
					</Typography>

					<FlexContainer>
						<div>
							<TypographyParagraph variant="inherit">
								{t(
									`Velkommen til et kompakt kurs i 3D-printing! Dette kurset gir deg en effektiv og rett-på-sak innføring i det du trenger å vite for å starte med 3D-printing på labben.`
								)}
							</TypographyParagraph>
							<VideoButton onClick={handleOpen}>{t('Se video')}</VideoButton>
						</div>
						<img
							src={printer}
							alt="Printer"
							style={{ height: '350px', maxWidth: '100%' }}
						/>
					</FlexContainer>

					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="video-guide-modal"
						aria-describedby="modal-to-show-video-guide"
					>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: 1300,
								boxShadow: 24,
							}}
						>
							<video width="100%" controls autoPlay>
								<source src={videoGuide} type="video/mp4" />
							</video>
						</Box>
					</Modal>
				</GridItem>

				<div style={{ marginBottom: '50px' }}>
					<Accordion style={{ borderRadius: '10px', boxShadow: 'none' }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Trinn 1: Last ned en 3D-modell`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: '16px', backgroundColor: 'white' }}
						>
							<Typography variant="subtitle1">
								{t(
									`Begynn med å finne en modell du ønsker å printe. Populære nettsteder som `
								)}
								<a href="https://www.thingiverse.com/">Thingiverse</a>
								{t(
									` tilbyr et bredt spekter av ferdige modeller. Når du har funnet modellen, klikk på 'Download' for å laste den ned til datamaskinen din.`
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion style={{ borderRadius: '10px', boxShadow: 'none' }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Trinn 2: Importer Modellen til CURA`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: '16px', backgroundColor: 'white' }}
						>
							<Typography variant="subtitle1">
								{t(
									`CURA er et program som brukes til å forberede din 3D-modell for utskrift. Åpne CURA og dra den nedlastede filen direkte inn i programmet. Dette vil importere modellen din.`
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion style={{ borderRadius: '10px', boxShadow: 'none' }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Trinn 3: Juster Modellen i CURA`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: '16px', backgroundColor: 'white' }}
						>
							<Typography variant="subtitle1">
								{t(
									`Når modellen er importert, kan du studere og justere den etter behov. Hvis modellen ikke er riktig plassert, kan du enkelt justere posisjonen ved å høyreklikke på modellen og velge 'Center selected'. Hvis du har skalert eller rotert modellen og ønsker å tilbakestille disse endringene, høyreklikk og velg 'Reset all model transformations'.`
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion style={{ borderRadius: '10px', boxShadow: 'none' }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Trinn 4: Forbered Utskriften`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: '16px', backgroundColor: 'white' }}
						>
							<Typography variant="subtitle1">
								{t(
									`Etter eventuelle justeringer, vil du finne en knapp merket 'Print via USB' i nedre høyre hjørne av CURA. Over denne knappen vil du se estimert bruk av filament (i gram) og forventet tid det tar å printe. Disse opplysningene skal føres inn i LabQ sin iPad.`
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion style={{ borderRadius: '10px', boxShadow: 'none' }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Avanserte Innstillinger`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: '16px', backgroundColor: 'white' }}
						>
							<Typography variant="subtitle1">
								{t(
									`CURA tilbyr en rekke avanserte innstillinger for å forbedre utskriftskvaliteten. Dette inkluderer å endre tykkelsen og tettheten på utskriften, legge til støttematerialer og justere slicer-innstillinger. For mer detaljerte instruksjoner og tips, se vår introduksjonsvideo `
								)}

								<Button
									onClick={handleOpen}
									style={{
										padding: 0,
										minWidth: 'auto',
										backgroundColor: 'transparent',
										color: '#212349',
										textDecoration: 'underline',
										boxShadow: 'none',

										textTransform: 'none',
									}}
								>
									{t('her.')}
								</Button>
							</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
			</FaqPageWrapper>
		</Container>
	);
};

const FaqPageWrapper = styled.div`
	height: 100%;
	text-align: left;
	display: grid;
	grid-template-columns: auto;
	align-items: center;
`;

const GridItem = styled.div`
	min-height: 15rem;
	padding: 0.5rem;
	align-items: center;
	text-align: center;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: flex-start;

	justify-content: flex-start;
	gap: 20px;

	@media (max-width: 900px) {
		flex-direction: column;
		align-items: center;
	}
`;

const TypographyParagraph = styled(Typography)`
	margin-top: 1rem;
	line-height: 1.6;
	text-align: center;
	margin-top: 100px;
`;
const VideoButton = styled(Button)`
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: white;
	width: 150px;
	background-color: #212349;
`;

export default GetStartedPage;
