import axios from 'axios';

export const api_url = process.env.REACT_APP_API_URL || 'https://labq-develop-backend-app-service.azurewebsites.net/api';

axios.defaults.baseURL = api_url;

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.statusText === 'Unauthorized' && window.location.pathname !== '/login') {
			window.location.replace('/login');
		}	
		return Promise.reject(error);
	}
);

export const setAuthorizationToken = (token?: string): void => {
	if (token) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common['Authorization'];
	}
};

export default axios;
