import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	useTheme,
} from '@mui/material';
import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { EnqueuedUser, UserObj } from '../../../models/Queue.models';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	deleteFirstUserInQueue,
	firstUserInQueueSel,
	refreshPrinterAndQueue,
} from '../../../redux/Queue/queueSlice';
import CancelIcon from '@mui/icons-material/Cancel';
import { ErrorButton, SuccessButton } from '../../../general-components/Button';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export interface PrintIsFinishedDialogProps {
	open: boolean;
	onClose: () => void;
	user: UserObj;
}

const PrintIsFinishedDialog: React.FC<PrintIsFinishedDialogProps> = ({
	open,
	onClose,
	user,
}) => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const firstUserInQueue = useAppSelector(firstUserInQueueSel);

	const handleClose = () => {
		onClose();
	};

	const handlePrintIsFinishedClick = async (printSuccessful: boolean) => {
		const removedUser: EnqueuedUser = { ...firstUserInQueue };

		try {
			if (!removedUser.userId) {
				throw new Error();
			}
			await dispatch(deleteFirstUserInQueue(printSuccessful)).unwrap();
			dispatch(refreshPrinterAndQueue());

			enqueueSnackbar(
				`${removedUser.firstName} ${removedUser.lastName}` + t(' ble fjernet fra køen'),
				{
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
		} catch (error) {
			enqueueSnackbar(
				t('Noe gikk galt. Kunne ikke fjerne') +
					`${removedUser.firstName} ${removedUser.lastName}` +
					t('fra køen'),
				{
					variant: 'error',
					anchorOrigin: {
						vertical: `top`,
						horizontal: `center`,
					},
				}
			);
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
			maxWidth="sm"
		>
			<DialogTitle id="estimatePrintTime">{t('Er du sikker?')}</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1">
					{t('Ved å avslutte print vil')} {user.firstName} {user.lastName} 
					{t(' bli fjernet fra køen.')}
				</Typography>
				<br />

				<Typography variant="subtitle1">
					{t('Vennligst marker printen som')}{' '}
					<span style={{ color: theme.palette.error.main }}>{t('mislykket')}</span>{' '}
					{t('eller')}{' '}
					<span style={{ color: theme.palette.success.main }}>{t('vellykket')}</span>{' '}
					{t('for å avslutte print. Dette hjelper oss med å lage nyttig statistikk.')}
				</Typography>
				<br />
			</DialogContent>

			<DialogActions>
				<Button variant="text" onClick={handleClose}>
					{t('Avbryt')}
				</Button>
				<ErrorButton
					variant="contained"
					onClick={() => handlePrintIsFinishedClick(false)}
					startIcon={<CancelIcon />}
				>
					{t('Mislykket')}
				</ErrorButton>
				<SuccessButton
					variant="contained"
					onClick={() => handlePrintIsFinishedClick(true)}
					startIcon={<CheckCircle />}
				>
					{t('Vellykket')}
				</SuccessButton>
			</DialogActions>
		</Dialog>
	);
};

export default PrintIsFinishedDialog;
