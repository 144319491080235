import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HistoryBox from './History';
import {
	AggregateStats,
	PrintStats,
	TimePeriod,
} from '../../../redux/Stats/stats.models';
import MainStats from './MainStats';

interface Props {
	data: AggregateStats | undefined;
	isLoading: boolean;
	history: PrintStats[] | undefined;
	timePeriod: TimePeriod;
	setTimePeriod: React.Dispatch<React.SetStateAction<TimePeriod>>;
	isAdmin: boolean;
}

const Dashboard: React.FC<Props> = ({
	data,
	isLoading,
	history,
	timePeriod,
	setTimePeriod,
	isAdmin,
}: Props) => {
	const [showHistory, setShowHistory] = useState<boolean>(false);

	const { t } = useTranslation();

	const handleToggle = (
		event: React.MouseEvent<HTMLElement>,
		tab: TimePeriod
	) => {
		if (tab !== null) setTimePeriod(tab);
	};

	const handleShowHistory = () => {
		setShowHistory((prev) => !prev);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<ToggleButtonGroup
				value={timePeriod}
				exclusive
				onChange={handleToggle}
				size="small"
			>
				<ToggleButton sx={ToggleButtonStyle} value="ThisMonth">
					{t('Denne måneden')}
				</ToggleButton>
				<ToggleButton sx={ToggleButtonStyle} value="LastMonth">
					{t('Forrige måned')}
				</ToggleButton>
				<ToggleButton sx={ToggleButtonStyle} value="YearToDate">
					{t('År til dags dato')}
				</ToggleButton>
				<ToggleButton sx={ToggleButtonStyle} value="Last365Days">
					{t('Siste 365 dager')}
				</ToggleButton>
			</ToggleButtonGroup>

			<MainStats data={data} isLoading={isLoading} isAdmin={isAdmin} />

			<Button
				variant="text"
				color="primary"
				onClick={handleShowHistory}
				endIcon={showHistory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				sx={{ mb: 2, mt: 10 }}
			>
				{showHistory ? t('Lukk historikk') : t('Vis historikk')}
			</Button>

			{showHistory && (
				<HistoryBox history={history} isAdmin={isAdmin}></HistoryBox>
			)}
		</Box>
	);
};

const ToggleButtonStyle = {
	mt: 7,
	mb: 2,
	color: '#11133C',
	'&.Mui-selected': {
		backgroundColor: 'rgba(17, 19, 60, 0.9)',
		color: 'white',
		'&.Mui-selected.Mui-focusVisible': {
			background: 'rgba(17, 19, 60, 0.9)',
			color: 'white',
		},
		'&.Mui-selected.Mui-selected:hover': {
			backgroundColor: '#11133C',
			color: 'white',
		},
	},
	'&.MuiToggleButton-root:hover': {
		backgroundColor: 'rgba(17, 19, 60, 0.8)',
		color: 'white',
	},
	'&.MuiToggleButton-root.Mui-selected:hover': {
		backgroundColor: '#11133C',
		color: 'white',
	},
};
export default Dashboard;
