import React from 'react';
import { lighten, useTheme, Container } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';
import CompletedPrintsList from './CompletedPrintsList';

export interface CompletedPrintsSectionProps {}

const CompletedPrintsSection: React.FC<CompletedPrintsSectionProps> = () => {
	const theme = useTheme();
	return (
		<>
			<br />
			<br />
			<SVGWrapper>
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1200 120"
					preserveAspectRatio="none"
					className="svg"
					fill={lighten(theme.palette.secondary.main, 0.6)}
					style={{
						height: '69px',
						width: '100%',
						opacity: 1,
						transform: 'rotateX(180deg)',
						verticalAlign: 'top',
					}}
				>
					<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
				</svg>
			</SVGWrapper>
			<CompletedWrapper>
				<Container>
					<CompletedPrintsList />
				</Container>
			</CompletedWrapper>
			<SVGWrapper>
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1200 120"
					preserveAspectRatio="none"
					className="svg"
					fill={lighten(theme.palette.secondary.main, 0.6)}
					style={{
						height: '69px',
						width: '230%',
						opacity: 1,
						transform: 'rotateX(0deg) rotateY(0deg)',
						verticalAlign: 'top',
					}}
				>
					<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
				</svg>
			</SVGWrapper>
		</>
	);
};

const CompletedWrapper = withTheme(styled.div`
	padding: 5rem 0;
	background: ${(props) => lighten(props.theme.palette.secondary.main, 0.6)};
	min-height: 12rem;
	display: grid;
	place-items: center;
`);

const SVGWrapper = styled.div`
	overflow: hidden;
`;

export default CompletedPrintsSection;
