import { Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EstimatePrintTimeDialog, {
	EstimateType,
} from './EstimatePrintTime.Dialog';
import { useTranslation } from 'react-i18next';

export interface EstimatePrintTimeProps {
	estimateType: EstimateType;
}

const EstimatePrintTime: React.FC<EstimatePrintTimeProps> = ({
	estimateType,
}) => {
	const [showDialog, setShowDialog] = useState<boolean>(false);

	const { t } = useTranslation();

	const handleCloseDialog = () => {
		setShowDialog(false);
	};

	const handleOpenDialog = () => {
		setShowDialog(true);
	};

	return (
		<>
			<Button
				color="secondary"
				variant="contained"
				endIcon={<ScheduleIcon />}
				onClick={handleOpenDialog}
			>
				{estimateType === 'estimateTime' ? t('Start print') : t('Legg til tid')}
			</Button>
			<EstimatePrintTimeDialog
				open={showDialog}
				onClose={handleCloseDialog}
				estimateType={estimateType}
			/>
		</>
	);
};

export default EstimatePrintTime;
