import { TextField, Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';

export const FormWrapper = withTheme(styled.div`
	margin: 0.5rem;
`);

export const StyledForm = withTheme(styled.form`
	margin-top: 1rem;
	display: grid;
	place-items: center;
	& > * {
		width: 22rem;
	}
`);

export const StyledTextField = withTheme(styled(TextField)`
	margin-top: 1rem;
`);

export const ErrorTypography = withTheme(styled(Typography)`
	color: ${(props) => props.theme.palette.error.main};
`);

export const DivThatIsStyled = withTheme(styled.div`
	display: flex;
	justify-content: center;
`);
