import { useState } from 'react';
import { StyledForm } from '../../../styledcomponents/SimpleForms/SimpleForm';
import { StyledTextField } from '../../AdminPage/components/PrinterForm';
import { ReserveButton } from '../../../general-components/Button';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
	refreshPrinterAndQueue,
	enqueueUserVIP,
} from '../../../redux/Queue/queueSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { UserVipObj } from '../../../models/Queue.models';
import { userIdSel } from '../../../redux/User/userSlice';
import { StyledDiv } from '../../AdminPage/components/PrintersTable';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

const QueueActionsVIP = () => {
	const [customer, setCustomer] = useState<string>('');
	const [project, setProject] = useState<string>('');

	const dispatch = useAppDispatch();
	const userId = useAppSelector(userIdSel);

	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleEnqueueVip = async () => {
		try {
			if (!userId) {
				throw new Error();
			}

			const userVipObj: UserVipObj = {
				userId: userId,
				customer: customer,
				project: project,
			};

			await dispatch(enqueueUserVIP(userVipObj)).unwrap();
			dispatch(refreshPrinterAndQueue());

			enqueueSnackbar(t('Du er lagt til i kø!'), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (e) {
			enqueueSnackbar(t('Noe gikk galt! Prøv på nytt.'), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		}
	};

	return (
		<StyledForm onSubmit={handleEnqueueVip}>
			<StyledTextField
				label={t('Kunde')}
				value={customer}
				required={true}
				onChange={(event) => setCustomer(event.target.value)}
			/>
			<StyledTextField
				label={t('Prosjekt')}
				value={project}
				required={true}
				onChange={(event) => setProject(event.target.value)}
			/>
			<StyledDiv>
				<ReserveButton
					type="submit"
					color="secondary"
					variant="contained"
					endIcon={<AddToQueueIcon />}
				>
					{t('Still meg i prioritert kø')}
				</ReserveButton>
			</StyledDiv>
		</StyledForm>
	);
};
export default QueueActionsVIP;
