import React from 'react';
import styled from 'styled-components';
import {
	ButtonBase,
	Container,
	lighten,
	Typography,
	useTheme,
	darken,
} from '@mui/material';
import { withTheme } from '@mui/styles'
import { Alignment } from '../../../styledcomponents/General/alignment';
import { TypographyIcon } from '../../../styledcomponents/Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { StyledLink } from '../../../styledcomponents/ReactRouter/ReactRouter';
import { useAppSelector } from '../../../redux/hooks';
import {
	acceptedLocationSelector,
	locationIdSel,
} from '../../../redux/User/userSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface RegisterOrLoginHeroSectionProps {}

const RegisterOrLoginHeroSection: React.FC<RegisterOrLoginHeroSectionProps> =
	() => {
		const theme = useTheme();
		const locationId = useAppSelector(locationIdSel);
		const navigate = useNavigate();
		const acceptedLocation = useAppSelector(acceptedLocationSelector);
		const { t } = useTranslation();

		const handleRouteChange = (route: string): void =>
			navigate(`/${route}`);
		return (
			<>
				<SVGWrapper>
					<svg
						data-name="Layer 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1200 120"
						preserveAspectRatio="none"
						className="svg"
						fill={lighten(theme.palette.secondary.main, 0.6)}
						style={{
							height: '69px',
							width: '120%',
							opacity: 1,
							transform: 'rotateX(180deg)',
							verticalAlign: 'top',
						}}
					>
						<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
					</svg>
				</SVGWrapper>
				<ContentWrapper>
					<Container>
						<InnerWrapper>
							<Alignment.CenterAlignHorVerWrapper>
								<StyledLink to={'/login'}>
									<BlobButton onClick={() => handleRouteChange('login')}>
										<TypographyIcon variant="h5">
											<FontAwesomeIcon icon={faSignInAlt} />
											{t('Logg inn')}
										</TypographyIcon>
									</BlobButton>
								</StyledLink>

								<ExplanationTypography variant="subtitle1">
									{t('Dersom du allerede har')} <br />
									{t('registrert en bruker')}
								</ExplanationTypography>
							</Alignment.CenterAlignHorVerWrapper>

							<Alignment.CenterAlignHorVerWrapper>
								<BlobButton
									onClick={() => handleRouteChange('register')}
									disabled={!acceptedLocation}
								>
									<TypographyIcon variant="h5">
										<FontAwesomeIcon icon={faUserPlus} />
										{t('Registrer bruker')}
									</TypographyIcon>
								</BlobButton>

								{locationId ? (
									<ExplanationTypography variant="subtitle1">
										{t('Dersom du ikke har')} <br /> {t('registrert en bruker')}
									</ExplanationTypography>
								) : (
									<ExplanationTypography
										variant="subtitle1"
										style={{ color: 'red' }}
										align="center"
									>
										{t('Du må være på Bouvet-nett for')} <br /> 
										{t('å registrere en ny bruker')}
									</ExplanationTypography>
								)}
							</Alignment.CenterAlignHorVerWrapper>
						</InnerWrapper>
					</Container>
				</ContentWrapper>
				<SVGWrapper>
					<svg
						data-name="Layer 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1200 120"
						preserveAspectRatio="none"
						className="svg"
						fill={lighten(theme.palette.secondary.main, 0.6)}
						style={{
							height: '69px',
							width: '190%',
							opacity: 1,
							transform: 'rotateX(0deg) rotateY(0deg)',
							verticalAlign: 'top',
						}}
					>
						<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
					</svg>
				</SVGWrapper>
			</>
		);
	};

const ContentWrapper = withTheme(styled.div`
	padding: 5rem 0;
	background: ${(props) => lighten(props.theme.palette.secondary.main, 0.6)};
	min-height: 15rem;
	display: grid;
	place-items: center;
`);

const SVGWrapper = styled.div`
	overflow: hidden;
`;

const InnerWrapper = withTheme(styled.div`
	display: grid;
	grid-template-columns: auto auto;
	${(props) => props.theme.breakpoints.down('xs')} {
		grid-template-columns: auto;
	}
`);

const BlobButton = withTheme(styled(ButtonBase)`
	padding: 4.5rem 2rem;
	border-radius: 58% 42% 63% 37% / 55% 53% 47% 45%;
	box-shadow: ${(props) => props.theme.shadows[9]};
	background: ${(props) => props.theme.palette.tertiary.main};
	transition: all 200ms;
	&:hover {
		background: ${(props) => darken(props.theme.palette.tertiary.main, 0.2)};
		border-radius: 63% 37% 61% 39% / 47% 64% 36% 53%;
	}
	&:disabled {
		background: ${(props) => props.theme.palette.grey[300]};
		box-shadow: none;
		color: grey;
	}
`);

const ExplanationTypography = withTheme(styled(Typography)`
	margin-top: 1rem;
	${(props) => props.theme.breakpoints.down('xs')} {
		margin-bottom: 3rem;
	}
`);

export default RegisterOrLoginHeroSection;
