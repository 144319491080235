import { FieldError } from 'react-hook-form';
import { ErrorTypography } from '../styledcomponents/SimpleForms/SimpleForm';
import { useTranslation } from 'react-i18next';

export const FormErrorMessage = ({ error }: { error: FieldError }) => {
	const { t } = useTranslation();

	return (
		<>
			{error && error.type === 'required' && (
				<ErrorTypography>{error.message}</ErrorTypography>
			)}
			{error && error.type === 'maxLength' && (
				<ErrorTypography>{t('Maks lengde overskridet.')}</ErrorTypography>
			)}
			{error && error.type === 'minLength' && (
				<ErrorTypography>{t('Minimumslengde ikke oppfylt.')} </ErrorTypography>
			)}
		</>
	);
};
