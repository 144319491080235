import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';
import { QueueState } from '../../../models/Queue.models';
import { TypographyIcon } from '../../../styledcomponents/Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faClock,
	faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useTranslation } from 'react-i18next';

export interface PrintStatusTagProps {
	queueState: QueueState;
}

const PrintStatusTag = ({ queueState }: PrintStatusTagProps) => {
	const { t } = useTranslation();

	const getDisplayStringFromTagType = (queueState: QueueState): string => {
		switch (queueState) {
			case QueueState.ReadyToPrint:
				return t('Klar for å printe');
			case QueueState.Printing:
				return t('Printer nå');
			case QueueState.Finished:
				return t('Tid er utgått');
			case QueueState.Completed:
				return t('Avsluttet');
			default:
				return '';
		}
	};

	const getIconFromTagType = (queueState: QueueState): JSX.Element => {
		switch (queueState) {
			case QueueState.ReadyToPrint:
				return <FontAwesomeIcon icon={faPlayCircle} />;
			case QueueState.Printing:
				return <FontAwesomeIcon icon={faClock} />;
			case QueueState.Finished:
				return <HourglassEmptyIcon />;
			case QueueState.Completed:
				return <FontAwesomeIcon icon={faCalendar} />;
			default:
				return <></>;
		}
	};

	if (queueState === QueueState.InQueue) return <></>;

	return (
		<PrintStatusTagWrapper>
			<TypographyIcon variant="h6">
				{getIconFromTagType(queueState)}
				{getDisplayStringFromTagType(queueState)}
			</TypographyIcon>
		</PrintStatusTagWrapper>
	);
};

const PrintStatusTagWrapper = withTheme(styled.div`
	margin: 0 0.5rem;
	color: ${(props) => props.theme.palette.grey[200]};
	${(props) => props.theme.breakpoints.down('sm')} {
		margin-top: 1rem;
		color: ${(props) => props.theme.palette.grey[900]};
	}
	display: grid;
	place-items: center;
`);

export default PrintStatusTag;
