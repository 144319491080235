import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
	interface CustomTypeOptions {
		returnNull: false;
	}
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			no: {
				translation: require('./locales/no/translations.json'),
			},
			en: {
				translation: require('./locales/en/translations.json'),
			},
		},
		fallbackLng: 'no',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

i18n.languages = ['no', 'en'];
export default i18n;
