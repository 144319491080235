import axios from './api.config';
import { PrintStatsResponse } from '../redux/Stats/stats.models';

export namespace newStatsApi {
	export const fetchCustomerPrintHistory = async () => {
		try {
			const response = await axios.get<PrintStatsResponse[]>(
				`UserStatistics/get_customer_print_history`
			);
			return response.data;
		} catch (error) {
			console.error('Could not fetch data', error);
			throw error;
		}
	};
}
