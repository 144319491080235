import { useEffect } from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import './App.css';
import MainRouter from './MainRouter';
import { theme } from './theme';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import StylesProvider from '@mui/styles/StylesProvider';
import { useAppDispatch } from './redux/hooks';
import { getClientLocation, initialiseToken } from './redux/User/userSlice';
import BeatLoader from 'react-spinners/BeatLoader';
import { useState } from 'react';
import styled from 'styled-components';
import './i18n/i18n';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const App = () => {
	const dispatch = useAppDispatch();
	const [initAppLoading, setInitAppLoading] = useState<boolean>(true);

	const initialAppSetup = async () => {
		dispatch(initialiseToken());
		await dispatch(getClientLocation());
		setInitAppLoading(false);
	};

	useEffect(() => {
		initialAppSetup();
		// eslint-disable-next-line
	}, []);

	return (
        <StylesProvider injectFirst>
			<StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ConfirmProvider>
                        <SnackbarProvider maxSnack={5}>
                            {initAppLoading ? (
                                <LoadingWrapper>
                                    <BeatLoader color={theme.palette.primary.light} size={100} />
                                </LoadingWrapper>
                            ) : (
                                <MainRouter />
                            )}
                        </SnackbarProvider>
                    </ConfirmProvider>
                </ThemeProvider>
            </StyledEngineProvider>
		</StylesProvider>
    );
};

const LoadingWrapper = styled.div`
	display: grid;
	place-items: center;
	height: 100vh;
`;

export default App;
