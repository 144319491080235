import React from 'react';
import styled from 'styled-components';
import { Typography, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../redux/hooks';
import { firstNameSel } from '../../redux/User/userSlice';
import ProfileForm from './components/ProfileForm';
import { useState } from 'react';
import ChangePasswordForm from './components/ChangePasswordForm';
import { useTranslation } from 'react-i18next';

export type ActiveForm = 'profile' | 'password';

export interface ProfilePageProps {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
	const theme = useTheme();
	const firstName = useAppSelector(firstNameSel);
	const { t } = useTranslation();

	const [activeForm, setActiveForm] = useState<ActiveForm>('profile');

	return (
		<ProfilePageWrapper>
			<GridItem>
				<FontAwesomeIcon
					icon={faUser}
					size="10x"
					color={theme.palette.secondary.dark}
				/>
			</GridItem>

			<GridItem>
				<Typography variant="h3">{t('Hei')}, {firstName}</Typography>

				{activeForm === 'profile' && (
					<ProfileForm changeFormCallback={setActiveForm} />
				)}

				{activeForm === 'password' && (
					<ChangePasswordForm changeFormCallback={setActiveForm} />
				)}
			</GridItem>
		</ProfilePageWrapper>
	);
};

const ProfilePageWrapper = withTheme(styled.div`
	height: 100%;
	display: grid;
	grid-template-columns: auto;
`);

const GridItem = withTheme(styled.div`
	min-height: 15rem;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`);
export default ProfilePage;
