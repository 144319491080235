import React, { useState } from 'react';
import {
	AccordionDetails,
	AccordionSummary,
	darken,
	useMediaQuery,
	useTheme,
	Typography,
} from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled, { css } from 'styled-components';
import {
	QueueStateString,
	EnqueuedUser,
	CompletedEnqueuedUser,
	isHighlightQueueState,
} from '../../../models/Queue.models';
import { pulseAnimation } from '../../../animations/SimpleKeyFrames';
import PrintStatusTag from './PrintStatusTag';
import { motion } from 'framer-motion';
import { enqueuedUsersListChildVariant } from '../../../animations/framer-motion/enqueuedUsersList';
import { Accordion, AccordionProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { lighten } from '@mui/material';
import QueueItemDetailsAccordion from './QueueItemDetails.Accordion';
import { useAppSelector } from '../../../redux/hooks';
import { userIdSel } from '../../../redux/User/userSlice';
import RemainingTimeInStateCountdown from './RemainingTimeInStateCountdown';
import PrintSuccessfulTag from '../../../general-components/PrintSuccessfulTag';
import { Alignment } from '../../../styledcomponents/General/alignment';
import { TimeUtils } from '../../../utils/time.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { onTheLabSel } from '../../../redux/Location/locationSlice';
import { forwardRef } from 'react';

export interface QueueItemProps {
	enqueuedUser: EnqueuedUser | CompletedEnqueuedUser;
	onTheLab: boolean;
}

const QueueItem: React.FC<QueueItemProps> = ({
	enqueuedUser,
}: QueueItemProps) => {
	const onTheLab = useAppSelector(onTheLabSel);
	const { queueState } = enqueuedUser;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const userId = useAppSelector(userIdSel);

	const [expanded, setExpanded] = useState<boolean>(
		userId === enqueuedUser.userId && queueState !== 'Completed'
	);

	const handleExpandedChange = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<AccordionWrapper
				variants={enqueuedUsersListChildVariant}
				expanded={expanded}
				onChange={handleExpandedChange}
			>
				<AnimatedQueueItemAccordionSummary
					queuestate={queueState}
					variants={enqueuedUsersListChildVariant}
					expandIcon={
						<ExpandMoreIcon
							fontSize="large"
							style={{
								color: 'white',
							}}
						/>
					}
				>
					<Alignment.FlexRowCenterWrapper>
						<NameTypography
							variant={isHighlightQueueState(queueState) ? 'h5' : 'h6'}
							color="light-grey"
						>
							{userId && userId === enqueuedUser.userId ? (
								<b>
									{enqueuedUser.firstName} {enqueuedUser.lastName}
								</b>
							) : (
								`${enqueuedUser.firstName} ${enqueuedUser.lastName}`
							)}
						</NameTypography>

						{queueState === 'Completed' &&
							!isMobile &&
							(enqueuedUser as CompletedEnqueuedUser).finishedTime && (
								<Typography variant="subtitle1">
									{TimeUtils.dateToWordOr_dd_MM_yyyy_HH_mm(
										(enqueuedUser as CompletedEnqueuedUser).finishedTime
									)}
								</Typography>
							)}
					</Alignment.FlexRowCenterWrapper>

					<CenterAlignWrapper>
						{!isMobile &&
							(queueState === 'Completed' ? (
								<PrintSuccessfulTag
									printSuccessful={
										(enqueuedUser as CompletedEnqueuedUser).printSuccessful
									}
									typographyVariant="h6"
								/>
							) : (
								queueState !== 'InQueue' && (
									<PrintStatusTag queueState={queueState} />
								)
							))}

						{(queueState === 'Printing' || queueState === 'ReadyToPrint') &&
							enqueuedUser.remainingTimeInStateSec !== 0 && (
								<RemainingTimeInStateCountdown
									remainingTimeInStateSec={enqueuedUser.remainingTimeInStateSec}
								/>
							)}
					</CenterAlignWrapper>

					{queueState !== 'Completed' &&
						(userId === enqueuedUser.userId ? (
							<FontAwesomeIcon icon={faUserCircle} />
						) : (
							<span></span>
						))}
				</AnimatedQueueItemAccordionSummary>
				<QueueItemAccordionDetails queuestate={queueState}>
					<QueueItemDetailsAccordion
						onTheLab={onTheLab}
						enqueuedUser={enqueuedUser}
					/>
				</QueueItemAccordionDetails>
			</AccordionWrapper>
		</>
	);
};

const MotionAccordion = motion.create(
	forwardRef<HTMLDivElement, AccordionProps>(
		function MotionAccordionComponent(props, ref) {
			return <Accordion ref={ref} {...props} />;
		}
	)
);

const AccordionWrapper = withTheme(styled(MotionAccordion)`
	box-shadow: none;
	background: transparent;
	&:before {
		height: 0;
	}
	margin-bottom: 1rem;
	${(props) => props.theme.breakpoints.down('sm')} {
		margin-bottom: 0.6rem;
	}
`);

interface QueueItemAccordionSummaryProps {
	queuestate: QueueStateString;
}

const QueueItemAccordionSummary = withTheme(styled(
	AccordionSummary
)<QueueItemAccordionSummaryProps>`
	& > div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	border-radius: 10px;
	box-shadow: ${(props) => props.theme.shadows[10]};
	padding: ${(props) =>
		isHighlightQueueState(props.queuestate) === 'true'
			? ' 0.4rem 1.5rem'
			: ' 0 1.5rem'};
	${(props) => props.theme.breakpoints.down('sm')} {
		padding: 0 0.8rem;
	}
	background: ${(props) =>
		props.queuestate === 'Printing'
			? darken(props.theme.palette.info.main, 0.15)
			: props.queuestate === 'ReadyToPrint'
				? darken(props.theme.palette.tertiary.main, 0.25)
				: props.queuestate === 'Finished'
					? darken(props.theme.palette.error.main, 0.4)
					: props.queuestate === 'InQueue'
						? lighten(props.theme.palette.primary.dark, 0.1)
						: lighten(props.theme.palette.grey[900], 0.1)};

	background-size: 400% 400%;
	z-index: 10;

	color: ${(props) => props.theme.palette.grey[200]};

	animation: ${(props) =>
		props.queuestate === 'Printing' &&
		css`
			${pulseAnimation({
				iterationCount: 'infinite',
				timingFunction: 'ease-in-out',
				animationLengthMS: 5000,
				scaleReduce: 0.985,
			})}
		`};
`);

const AnimatedQueueItemAccordionSummary = ({ variants, ...props }: any) => (
	<motion.div variants={variants}>
		<QueueItemAccordionSummary {...props} />
	</motion.div>
);

const NameTypography = withTheme(styled(Typography)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 15rem;
	margin-right: 1rem;
`);

const CenterAlignWrapper = withTheme(styled.div`
	display: flex;
	align-items: center;
`);

const QueueItemAccordionDetails = withTheme(styled(
	AccordionDetails
)<QueueItemAccordionSummaryProps>`
	margin: -1rem 1rem 0 1rem;
	padding: 2rem 1rem 1rem 1rem;
	border-radius: 8px;
	box-shadow: ${(props) => props.theme.shadows[3]};
	background: ${(props) =>
		props.queuestate === 'Printing'
			? lighten(props.theme.palette.info.main, 0.85)
			: props.queuestate === 'ReadyToPrint'
				? lighten(props.theme.palette.tertiary.main, 0.8)
				: props.queuestate === 'Finished'
					? lighten(props.theme.palette.error.main, 0.85)
					: props.queuestate === 'InQueue'
						? lighten(props.theme.palette.primary.light, 0.65)
						: lighten(props.theme.palette.background.default, 0.05)};

	animation: ${(props) =>
		props.queuestate === 'Printing' &&
		pulseAnimation({
			iterationCount: 'infinite',
			timingFunction: 'ease-in-out',
			animationLengthMS: 5000,
			scaleReduce: 0.985,
		})};
`);

export default QueueItem;
