import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../redux/hooks';
import { logoutUserChain } from '../../redux/User/userSlice';
import LoginForm from './components/LoginForm';
import { useTranslation } from 'react-i18next';

export interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	useEffect(() => {
		dispatch(logoutUserChain());
		// eslint-disable-next-line
	}, []);

	return (
		<LoginPageWrapper>
			<GridItem>
				<FontAwesomeIcon
					icon={faKey}
					size="10x"
					color={theme.palette.secondary.dark}
				/>
			</GridItem>

			<GridItem>
				<Typography variant="h3">{t('Logg inn')}</Typography>

				<LoginForm />
			</GridItem>
		</LoginPageWrapper>
	);
};

const LoginPageWrapper = withTheme(styled.div`
	height: 100%;
	display: grid;
	grid-template-columns: auto;
`);

const GridItem = withTheme(styled.div`
	min-height: 15rem;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`);
export default LoginPage;
