export namespace StringUtils {
	export const addPhoneCode = (
		phoneNumber: string,
		countryCode: 'no' | 'se'
	): string => {
		return `${countryCode === 'no' ? '+47' : '+46'}${phoneNumber}`;
	};

	export const removePhoneCode = (phoneNumber: string): string => {
		return phoneNumber.slice(3);
	};

	export const add_s_ToName = (name: string): string => {
		return `${name}${name[name.length - 1] === 's' ? "'" : 's'}`;
	};
}
