import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { ErrorButton, SuccessButton } from '../../../general-components/Button';
import { useTranslation } from 'react-i18next';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { refreshPrinterAndQueue, restartPrintInQueue } from '../../../redux/Queue/queueSlice';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch, } from '../../../redux/hooks';
import { successSnack } from '../../AdminPage/components/LocationsTable';

export interface RestartPrintButtonProps {
    disabled: boolean;
}

const RestartPrintButton: React.FC<RestartPrintButtonProps> = ({ disabled }) => {
    const dispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleConfirm = () => {
        handleClose();
        try {
			if(!disabled) {
			dispatch(restartPrintInQueue());
            dispatch(refreshPrinterAndQueue());
			}
            
            enqueueSnackbar(t('Print ble restartet!'), {
                ...successSnack,
                variant: 'info',
            });
        } catch (error) {
            enqueueSnackbar(t('Noe gikk galt. Kunne ikke restarte.'), {
                ...successSnack,
                variant: 'error',
            });
        }
    };

    return (
        <>
            <ErrorButton
                variant="contained"
                onClick={handleClickOpen}
                color="primary"
                disabled={disabled}
            >
                {t('Restart print')}
                <RestartAltIcon />
            </ErrorButton>

            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>{t('Restart printer')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Er du sikker på at du vil restarte printen?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('Avbryt')}
                    </Button>
                    <SuccessButton
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        autoFocus>
                        {t('Restart')}
                    </SuccessButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RestartPrintButton;
