import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { Location } from '../../../redux/Location/locationSlice.model';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface SelectLocationProps {
	locations: Location[];
	placeholder?: string;
	value: number | undefined | null;
	handleChange: (value: number) => void;
	includeAllLocations: boolean;
}

const SelectLocation: React.FC<SelectLocationProps> = ({
	locations,
	placeholder,
	value,
	handleChange,
	includeAllLocations = false,
}) => {
	const { t } = useTranslation();

	if (!locations) return <></>;

	return (
		<StyledSelect
			placeholder={placeholder}
			value={value}
			onChange={(e: any) => handleChange(e.target.value)}
		>
			{includeAllLocations && <MenuItem value={-1}>{t('Alle kontor')}</MenuItem>}
			{locations.map((location) => (
				<MenuItem key={location.id} value={location.id}>
					{location.name}
				</MenuItem>
			))}
		</StyledSelect>
	);
};

const StyledSelect = styled(Select)`
	margin: 20px 0;
`;

export default SelectLocation;
