export const enqueuedUsersListVariants = {
	initial: {
		opacity: 0.5,
	},
	in: {
		opacity: 1,
		transition: {
			delayChildren: 0.2,
			staggerChildren: 0.35,
		},
	},
};

export const enqueuedUsersListChildVariant = {
	initial: {
		opacity: 0,
		y: 40,
	},
	in: {
		opacity: 1,
		y: 0,
	},
};

export const enqueuedUsersListTransition = {
	type: 'spring',
	ease: 'easeInOut',
	duration: 0.5,
};
