import React, { useState } from 'react';
import Dashboard from './components/Dashboard';
import { TimePeriod } from '../../redux/Stats/stats.models';
import { useSelector } from 'react-redux';
import { userIdSel } from '../../redux/User/userSlice';
import {
	useGetAllPrintsForPeriodQuery,
	useGetAverageStatsQuery,
	useGetUserAggregateStatsQuery,
	useGetUserPrintsForPeriodQuery,
} from '../../api/stats.api';
import { Container } from '@mui/material';

interface Props {
	isAdmin: boolean;
}

const DashboardPage: React.FC<Props> = ({ isAdmin }: Props) => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>('ThisMonth');
	const userId = useSelector(userIdSel);

	const userHistory = useGetUserPrintsForPeriodQuery(
		{ userId: userId, timePeriod: timePeriod },
		{ skip: isAdmin }
	);

	const userStats = useGetUserAggregateStatsQuery(
		{ userId: userId, timePeriod: timePeriod },
		{ skip: isAdmin }
	);

	const allPrints = useGetAllPrintsForPeriodQuery(
		{ timePeriod: timePeriod },
		{ skip: !isAdmin }
	);

	const adminStats = useGetAverageStatsQuery(
		{ timePeriod: timePeriod },
		{ skip: !isAdmin }
	);

	const DashboardComponent = (
		<Dashboard
			data={isAdmin ? adminStats.data : userStats.data}
			isLoading={isAdmin ? adminStats.isLoading : userStats.isLoading}
			history={isAdmin ? allPrints.data : userHistory.data}
			timePeriod={timePeriod}
			setTimePeriod={setTimePeriod}
			isAdmin={isAdmin}
		/>
	);

	return isAdmin ? (
		DashboardComponent
	) : (
		<Container>{DashboardComponent}</Container>
	);
};

export default DashboardPage;
