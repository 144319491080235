import {
	Container,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import { Typography, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import React from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export interface FaqPageProps { }

const FaqPage: React.FC<FaqPageProps> = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Container maxWidth="sm">
			<FaqPageWrapper>
				<GridItem>
					<Typography color="secondary" variant="h2">
						<FontAwesomeIcon
							icon={faQuestionCircle}
							size="1x"
							color={theme.palette.secondary.main}
							style={{ marginRight: '1rem' }}
						/>
						FAQ{' '}
					</Typography>
					<Typography variant="h4" color={theme.palette.primary.main} fontFamily={''}>{t('Bruker')} </Typography>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px', }}

						>
							<Typography variant="h6" style={{ fontWeight: 'bold' }}>
								{t(`Hvordan registrerer jeg meg?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('register_on_intranet')}
								<strong>{t('send_code')}</strong>
								{t('fill_contact_info_and')}
								<strong>{t('send_code_again')}</strong>
								{t('enter_verification_and_pin')}
								<strong>{t('register')}</strong>
								{t('error_guidance_and_confirmation')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Mottar du ikke en vertifiseringskode?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`Start med å forsikre deg om at oppgitt telefunnummer er korrekt.
								Videre kan du forsøke å sende en ny kode. Fortsatt ikke mottatt
								en kode? Om du ikke planlegger å printe akkurat nå så kan du
								forsøke å registrere brukeren din senere, det kan være en
								midlertidig feil hos leverandøren av sms-tjenesten. Om du ikke
								ønsker/kan vente, kontakt ansvarlig ansatt for LabQ systemet.` )}

							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan endrer jeg profilinformasjonen min?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('click_name_top_right')}{' '}
								<strong>{t('profile')}</strong>{t('change_name_instructions')}{' '}
								<strong>{t('save')}</strong>{t('after_changes')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan endrer jeg telefonummeret mitt?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`I aktuell versjon av LabQ systemet er det dessverre ingen
								funksjonalitet for å endre telefunnummer. Du kan slette brukeren
								din og opprette en ny, eller kontakte ansvarlig ansatt for LabQ
								systemet.`)}
							</Typography>
						</AccordionDetails>
					</Accordion>


					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan endrer jeg pinkoden min?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('navigate_and_click')}<strong>{t('change_pincode')}</strong>
								{t('pincode_instructions')}<strong>{t('update_password')}</strong>
								{t('completion_guidance')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Jeg har glemt pinkoden min`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`I aktuell versjon av LabQ systemet er det dessverre ingen
								funksjonalitet for glemt PIN-kode. Du kan slette brukeren din og
								opprette en ny, eller kontakte ansvarlig ansatt for LabQ
								systemet.`)}
							</Typography>
						</AccordionDetails>
					</Accordion>


					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan sletter jeg brukeren min?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('navigate_to_profile_change_page')}
								<strong>{t('delete_user')}.</strong>
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Typography variant="h4" color={theme.palette.primary.main}>{t('Kø')} </Typography>
					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan stiller jeg meg i kø?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('go_to_labq_and_login')}
								<strong>{t('home')}</strong>
								{t('printer_overview_instruction')}
								<strong>{t('join_queue')}.</strong>
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Når er det min tur til å printe?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`Du vil motta en melding når du er først i køen. Du kan også
								holde deg oppdatert på køen ved å gå til LabQ nettsiden og logge
								inn med din bruker.`)}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Jeg rekker ikke å starte printen innen gitt tidspunkt.`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('if_you_miss_print_time')}
								<strong>{t('move_back')}.</strong>
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan registrere startet print?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('start_print_instructions')}
								<strong>{t('start_print')}</strong>
								{t('estimate_time_instructions')}
								<strong>{t('start_print')}.</strong>
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Printen varer lengere enn oppgitt. Hva gjør jeg?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('add_time_instructions')}
								<strong>{t('add_time')}</strong>
								{t('dialog_box_instructions')}
								<strong>{t('add_time')}.</strong>
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Jeg er ferdig med å printe. Hva gjør jeg?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('end_print_instructions')}
								<strong>{t('end_print')}</strong>
								{t('choose_success_or_failure')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvordan fjerne meg fra køen?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('navigate_to_queue_instructions')}
								<strong>{t('remove_from_queue')}</strong>
								{t('in_your_queue_slot')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Printeten er ferdig eller har gått galt, men printtiden er ikke over. Hva gjør jeg?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('add_time_from_tablet')}
								<strong>{t('end_print')}</strong>
								{t('choose_print_outcome')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Typography variant="h4" color={theme.palette.primary.main}>{t('Printerinformasjon')} </Typography>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hva betyr det som står i knappen til en printer?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t('each_printer_has_id')}
								<strong>{t('printer_example')}</strong>
								{t('printer_id_function')}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Typography variant="h4" color={theme.palette.primary.main}>{t('Tekstmeldinger')} </Typography>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvilke tekstmeldinger vil jeg motta fra LabQ?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`Du vil motta meldinger når du registrerer deg, når du er nummer
								to i køen, når du er først i køen, når estimert tid for printet
								ditt har gått ut, når printen din avsluttes, og når du ikke
								starter printen din i tide.`)}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Kan jeg slå av tekstmeldinger?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`I aktuell versjon av LabQ systemet er det dessverre ingen
								funksjonalitet for å slå av tekstmeldinger fra LabQ.`)}
							</Typography>
						</AccordionDetails>
					</Accordion>

					<Typography variant="h4" color={theme.palette.primary.main}>
						{t('Informasjonsinnhenting og personalia')}{' '}
					</Typography>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hva skjer når jeg sletter brukeren min?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1"></Typography>
							{t(`All din personalia slettes fra LabQ systemet og dets databaser.
							Data fra dine print vil fortsatt være lagret, men da uten
							tilknytning til deg.`)}
						</AccordionDetails>
					</Accordion>

					<Accordion style={{ borderRadius: '10px', margin: '1rem', boxShadow: "none" }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ borderRadius: '5px 5px 5px 5px' }}
						>
							<Typography variant="h6" style={{ fontWeight: 'Bold' }}>
								{t(`Hvorfor må man registrere print som er velykket eller misslykket?`)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '16px', backgroundColor: 'white' }}>
							<Typography variant="subtitle1">
								{t(`Det er for statistiske årsaker. Om mange print mislykkes på en
								printer kan det for eksempel være noe galt med printeren, så den
								trenger reparasjon/justeringer.`)}
							</Typography>
						</AccordionDetails>
					</Accordion>
				</GridItem>
				<br />
				<br />
			</FaqPageWrapper>
		</Container>
	);
};

const FaqPageWrapper = withTheme(styled.div`
	height: 100%;
	text-align: left;
	display: grid;
	grid-template-columns: auto;
`);

const GridItem = withTheme(styled.div`
	min-height: 15rem;
	padding: 0.5rem;
	& > h2 {
		margin-top: 2rem;
		text-align: center;
	}
	& > h4 {
		margin-top: 2rem;
		margin-bottom: 1rem;
		text-align: start;
		
		font-weight: bold;
		text-align: center;
		
	}
	& > h5 {
		margin-top: 1rem;
	}
	& > p {
		margin-top: 1rem;
	}
`);
export default FaqPage;
