import {
	UserResponse,
	LoginResponse,
	UserRequestBody,
} from '../redux/User/userSlice.models';
import { UserObj } from './../models/Queue.models';
import axios from './api.config';
import { ObjectUtils } from '../utils/object.utils';

export namespace UserApi {
	export const registerUser = async (user: UserObj): Promise<number> => {
		const response = await axios.post<number>('Users/register', {
			firstName: user.firstName,
			lastName: user.lastName,
			phoneNumber: user.phoneNumber,
			verificationCode: user.verificationCode,
			password: user.pinCode,
			confirmPassword: user.pinCode,
		});

		return response.data;
	};

	export const loginUser = async (user: UserObj): Promise<LoginResponse> => {
		const response = await axios.post<LoginResponse>('Users/login', {
			phoneNumber: user.phoneNumber,
			password: user.pinCode,
		});
		return response.data;
	};

	export const getUser = async (
		userId: number | null
	): Promise<UserResponse> => {
		const response = await axios.get<UserResponse>(`Users/get_user_${userId}`);
		return response.data;
	};

	export const updateUser = async (
		userId: number | null,
		userRequest: UserRequestBody
	): Promise<UserResponse> => {
		const response = await axios.put<UserResponse>(
			`Users/update_user_${userId}`,
			{
				...ObjectUtils.removeNullOrUndefinedValues(userRequest),
			}
		);
		return response.data;
	};

	export const deleteUser = async (userId: number): Promise<void> => {
		const response = await axios.delete(`Users/delete_user_${userId}`);
		return response.data;
	};

	export const sendVerificationCode = async (
		phoneNumber: string
	): Promise<void> => {
		return await axios.post('Users/send_verification_code', {
			phoneNumber: phoneNumber,
		});
	};

	export const getUsers = async (): Promise<UserResponse[]> => {
		const response = await axios.get<UserResponse[]>(`Users/all`);
		return response.data;
	};

	export const resetPassword = async (phoneNumber: string): Promise<void> => {
		return await axios.put('Users/reset_password', {
			phoneNumber: phoneNumber,
		});
	};
}
